import React from 'react';
import { Navigate, useParams} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';


export const PrivateRoutesAdmin = ({element}) =>{
    const {id} = useParams();
    const {token} = useParams()  
    const  localId  = localStorage.getItem('id');  
    const authToken = localStorage.getItem('authToken');
  
  
    if (!authToken) {
      return <Navigate to="/LoginAdmin" />;
    }
  
    if(token !== authToken){
      return <Navigate to="/LoginAdmin"/>;
    }
  
    try {
      // Decodifica o token para obter o id do usuário
      const decodedToken = jwtDecode(authToken);
      const currentTime = Date.now() /1000;
      const userId = decodedToken.id;
  
  
      if(currentTime > decodedToken.exp){
          return <Navigate to="/LoginAdmin"/>;
      }
      
  
      if (parseInt(localId) !== userId || parseInt(id) !== userId ) {
  
        return <Navigate to="/LoginAdmin" />;
  
      };
      
  
      return element;
    } catch (error) {
      console.error('Erro ao decodificar o token:', error);
      return <Navigate to="/LoginAdmin" />;
    }
}