import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import './ServiceSearch.css'; // Supondo que você tenha um arquivo CSS para estilos
import logo from './logo-ze.svg';
import BackButton from '../../components/botãodevoltar';

const ServiceRequest = () => {
  const [nomeServico, setNomeServico] = useState('');
  const [services, setServices] = useState([])
  const [categoria, setCategoria] = useState('');
  const [userData, setUserData] = useState(null);
  const [cords, setCords] = useState({})
  const navigate = useNavigate();
  const id = localStorage.getItem("userId");

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject(new Error('Geolocalização não suportada pelo navegador.'));
      }
    });
  };

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const pos = await getCurrentLocation();
        const coords = [pos.coords.latitude, pos.coords.longitude];
        setCords(coords)
        const response = await axios.post(`https://app.chamaoseuze.com.br/updateLocationUser/${id}`, {
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        })

      } catch (error) {
        console.error('Erro ao obter localização do prestador:', error.message);
      }
    };

    fetchUserLocation();

  }, []);

  useEffect(() => {
    const fetchServies = async () => {
      try {

        const response = await axios.get("https://app.chamaoseuze.com.br/todos-servicos");
        setServices(response.data);

      } catch (error) {
        console.error(error.message || error.data);
      }
    }

    fetchServies();
  }, [])




  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://app.chamaoseuze.com.br/cliente/${id}`);
        setUserData(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados do cliente:', error);
      }
    };

    fetchUserData();
  }, [id]);

  const handleNomeServicoChange = (event) => {
    setNomeServico(event.target.value);
  };

  const handleCategoriaChange = (event) => {
    setCategoria(event.target.value);
  };

  const handleSaveEvent = async (e) => {
    e.preventDefault();
    const agora = new Date();
    const dataAtual = agora.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    const horaAtual = agora.toTimeString().split(' ')[0]; // Formato HH:MM:SS

    try {
      const response = await axios.post('https://app.chamaoseuze.com.br/criar-pedido', {
        nome_servico: nomeServico,
        categoria,
        preco: 50,
        cliente: userData.nome,
        idcliente: id,
        status: 'Pendente',
        endereco: userData.endereco,
        numero: userData.numeroCasa,
        date: dataAtual,
        time: horaAtual
      });

      alert('Pedido de serviço criado com sucesso!');
      navigate(`/aguardando-aceitacao/${response.data.id}`);
    } catch (error) {
      console.error('Erro ao criar pedido de serviço:', error);
    }
  };

  const handleGoBack = () => {
    navigate('/principal');
  };

  return (
    <div className="service-request">
      <BackButton onClick={handleGoBack} className="custom-back-button" />       <Link to={`/servicosemandamentosclientes/${id}`} className='botaoparaotatela'>Não Finalizados</Link>
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Criar Pedido de Serviço</h1>
      </div>
      <div className="request-form">
        <form onSubmit={handleSaveEvent}>
          <div className="form-group">
            <label>Nome do Serviço</label>
            <input
              type="text"
              placeholder="Nome do Serviço"
              value={nomeServico}
              onChange={handleNomeServicoChange}
              className="input-field"
              required
            />
          </div>
          <div className="form-group">
            <label>Categoria</label>
            <select
              value={categoria}
              onChange={handleCategoriaChange}
              className="input-field"
              required
            >
              {services.map((service) => (
                <option value={service.nome_servico} >{service.nome_servico}</option>
              ))}
            </select>
          </div>
          <button type="submit" className="submit-button">
            Criar Pedido
          </button>
        </form>
      </div>

    </div>
  );
};

export default ServiceRequest;
