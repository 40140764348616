const TermoCliente = () =>{
    return(
        <p>
            TERMOS E CONDIÇÕES GERAIS DE USO E ACESSO A PRODUTOS E SERVIÇOS – PLATAFORMA CHAMA O SEU ZÉ

CONSIDERANDO que a plataforma CHAMA SEU ZÉ foi criada com o propósito de ser facilitadora tecnológica do encontro entre prestadores de serviço, fornecedores e seus potenciais clientes;
CONSIDERANDO que a plataforma tem como meio e fim o papel de educar e transformar a realidade, principalmente dos agentes informais de prestação de serviços, para o desenvolvimento de uma sociedade mais livre, justa e solidária;
CONSIDERANDO que a empresa CHAMA O SEU ZÉ atua exclusivamente como intermediadora de serviços, facilitando o contato entre prestadores, tomadores de serviços e fornecedores de materiais, ferramentas e equipamentos, sem exercer qualquer controle direto sobre a execução dos serviços contratados, ou mesmo sobre negociações, sendo, portanto, parte intermediadora e não executante;
CONSIDERANDO que o contratante reconhece que não há qualquer vínculo empregatício, sociedade, grupo econômico ou qualquer espécie de ajuste comercial entre a empresa CHAMA O SEU ZÉ e os prestadores de serviços ou os tomadores de serviços, haja vista a natureza autônoma e independente da relação entre as partes, em conformidade com os princípios da autonomia privada e da livre iniciativa;
CONSIDERANDO que não há configuração de hipossuficiência entre a plataforma e o Usuário, em razão da CHAMA O SEU ZÉ atuar somente como intermediadora e não prestadora direta ou indireta de serviços;
CONSIDERANDO que o prestador de serviço e a plataforma são agentes autônomos e independentes entre si, desenvolvendo atividade empresarial, independentemente da roupagem adotada de pessoa física, jurídica ou ente despersonalizado, portanto, plenamente capazes de gerir suas atividades econômicas, de acordo com o art. 966, parágrafo único, do Código Civil Brasileiro, sem qualquer vínculo para execução direta da prestação de serviço;
CONSIDERANDO que as partes envolvidas reconhecem expressamente que esta relação não configura contrato de consumo, nos termos do Código de Defesa do Consumidor (Lei nº 8.078/1990), por tratar-se de uma negociação direta entre consumidor final e o prestador de serviço e/ou fornecedor que se cadastra na plataforma;
CONSIDERANDO que o Usuário compreende e aceita os termos e condições de forma livre e consciente, assumindo todas as obrigações e responsabilidades decorrentes deste contrato sem qualquer presunção de desigualdade jurídica ou econômica entre elas, reconhecendo a plataforma como facilitadora do atendimento, e não prestadora do serviço;
O usuário resolve aderir o seguinte termo de condições e usos:
DAS PARTES
CONTRATADA: CHAMA O SEU ZÉ - TECNOLOGIA LTDA (“Plataforma” ou “Aplicativo” ou “Intermediadora”) pessoa jurídica de direito privado devidamente inscrita no CNPJ/MF sob o n. 56.015.414/0001-49, com sede na Avenida Brigadeiro Faria Lima, n. 1572, sala 1022, CEP n. 01.451-917, na cidade e comarca de São Paulo, Estado de São Paulo, neste ato representada por seus sócios administradores.
CONTRATANTE (“Usuário” ou “Cliente Final”): A pessoa física ou pessoa jurídica de direito privado devidamente qualificada nos moldes do "CADASTRO" realizado quando do primeiro acesso ao aplicativo cujos dados de coleta inicial (IP, RG, CPF, CNPJ, Nome, Email, Dados Geolocacionais) serão armazenados como registro e prova de legitimidade do aceite ao presente TCGP junto à CONTRATADA.
O Usuário declara que compreende e aceita estes Termos e Condições Gerais de Usos e Acessos a Produtos e Serviços ("TCGPS"), bem como seus respectivos aditivos, que estabelecem os termos e as condições pelas quais as Empresas da CHAMA O SEU ZÉ irão prover as Licenças, Acessos, Produtos e/ou Serviços ao Cliente. O Usuário declara que leu, está ciente e de acordo que: I- O TCGPS pode ser modificado a qualquer tempo, sem necessidade de notificação prévia, sendo que tais modificações tornar-se-ão válidas a partir da data do acesso ao SISTEMA. II- O usuário declara que as informações prestadas serão verdadeiras, exatas, atuais e completas, e que deverão ser mantidas atualizadas durante sua permanência como usuário da plataforma SISTEMA. III- O Cliente Final assume toda e qualquer responsabilidade pelo mau uso ou pela utilização do login e senha por terceiros não autorizados, ou pela falta de instalação e atualização de programas Anti Spywares, Anti-vírus e outros que impeçam a violação do sistema que é utilizado para ter acesso ao SISTEMA. 
Ao aceitar eletronicamente o presente TCGPS, por meio do clique no botão "Aceito o Termo", o Usuário estará automaticamente aderindo e concordando em se submeter integralmente a seus termos e condições e de qualquer de suas alterações futuras. 
1. GERAL
1.1. O Usuário aceita, por meio deste Contrato, os termos e condições gerais aplicáveis a todas as Licenças, Acessos, Produtos e/ou Serviços a serem providos pela CHAMA SEU ZÉ ao Usuário sem qualquer ressalva.
1.2. As "Partes" ou a parte devem ser interpretadas conforme o contexto. Todo(s) o(s) Formulário(s) de Pedido, Anexos, Ordens de Serviço ou qualquer confirmação do pedido celebrado(s) pelas mesmas entidades, juntamente com o TCGPS compreendem coletivamente um Contrato apenas entre essas entidades.
1. INTRODUÇÃO E DISPOSIÇÕES GERAIS
1.1. Bem-vindo à "CHAMA SEU ZÉ"! Estes Termos de Uso (“Termos”) regem o acesso e uso, como pessoa física ou jurídica, dos serviços oferecidos pelo Prestador cadastrado na Plataforma, disponibilizados pela CHAMA SEU ZÉ, ou qualquer de suas afiliadas, a toda e qualquer pessoa que utilize os Serviços na qualidade de cliente, a seguir denominado simplesmente “Usuário(s)”.
1.1.1. Por favor, leia atentamente estes Termos antes de acessar ou usar os Serviços.
1.2. ACEITAÇÃO DOS TERMOS E CONDIÇÕES DE USO. Ao se cadastrar e utilizar os Serviços, o Usuário declara ter lido, entendido e aceito os Termos. Caso, a qualquer tempo, o Usuário não concorde com os Termos, deverá cessar imediatamente a utilização do aplicativo e desinstalá-lo de seu aparelho.
1.2.1. Termos adicionais poderão se aplicar a determinados serviços, tais como condições para eventos ou promoções específicas, e esses Termos Adicionais serão divulgados junto aos respectivos Serviços. Os Termos Adicionais são complementares e prevalecerão sobre estes Termos em caso de conflito em relação a tais serviços específicos.
1.3. Comunicação com os Prestadores de Serviço. A "Chama Seu Zé" poderá enviar notificações administrativas no aplicativo, por e-mail, SMS, WhatsApp ou outros meios de comunicação com conteúdo informativo e promocional relacionado aos Serviços.
1.4. Alteração dos Termos. A "Chama Seu Zé" reserva-se o direito de realizar alterações e atualizações nos Termos a qualquer momento, sem aviso prévio. Em caso de alterações que restrinjam direitos dos Usuários, a "Chama Seu Zé" comunicará essas mudanças através dos Meios de Comunicação. Caso o Usuário não concorde com as alterações, deverá cancelar sua conta e cessar a utilização dos Serviços.
2. DADOS DO USUÁRIO E PRIVACIDADE
2.1. A CHAMA O SEU ZÉ possui uma política de privacidade que regula a coleta, o uso e o armazenamento de dados dos Usuários, conforme disposto em sua Política de Privacidade constante do Anexo 1 ao final do documento, sendo que a utilização dos Serviços implica na sua concordância.
3. CADASTRO
3.1. Para utilizar os Serviços, o Usuário deve criar e manter uma conta pessoal preenchendo os campos obrigatórios de cadastro. A veracidade e atualização dos dados fornecidos são de exclusiva responsabilidade do Usuário.
3.1.1. O Usuário declara ter, no mínimo, 18 anos ou possuir autorização de seus responsáveis legais para utilizar o aplicativo. 
3.1.2. Quando o Usuário for maior de 12 e menor de 18 anos, os genitores responderão de forma direta e solidária pelos atos praticados por estes, pelos quais ficarão corresponsáveis por quaisquer contratações.
4. SERVIÇOS
4.1. A CHAMA O SEU ZÉ oferecerá intermediação que permite ao Usuário localizar e contratar prestadores autônomos para serviços de transporte e assistência doméstica, como eletricistas, encanadores, pintores, entre outros (“Prestador(es) de Serviço(s)”).
4.2. A utilização dos Serviços é gratuita para o Usuário, salvo em casos específicos onde a CHAMA O SEU ZÉ informará previamente sobre qualquer cobrança adicional, ressalvados os custos diretos do prestador de serviço, os quais serão adimplidos exclusivamente por meio de pagamento disponibilizado pelo aplicativo, sob pena de multa de 30% do valor da contratação e, em caso de reincidência, poderá ser excluído de forma definitiva.
4.3. A CHAMA O SEU ZÉ não presta diretamente serviços de transporte, assistência doméstica ou qualquer outro que venha a ser disponibilizado, atuando apenas como intermediadora e facilitadora entre o Usuário e o Prestador de Serviços.
4.4. A responsabilidade pela execução dos serviços contratados é exclusivamente dos Prestadores de Serviço, que são autônomos e independentes da CHAMA O SEU ZÉ, sem qualquer vínculo de subordinação, ingerência, coligação ou hierarquia. A CHAMA O SEU ZÉ não se responsabiliza por quaisquer perdas, prejuízos ou danos decorrentes da prestação dos serviços por parte dos Prestadores, em qualquer esfera do direto.
4.5. O Usuário concorda em avaliar o serviço prestado e manter um ambiente de respeito e cordialidade, sendo vedados comentários de caráter ofensivo ou discriminatório. Assim, que qualquer ação de ressarcimento/reparação de danos sofridos pela plataforma em razão das condutas de seus usuários serão redirecionadas ao causador do ato/fato, pessoalmente responsável.
5. MODIFICAÇÕES NOS SERVIÇOS
5.1. A "Chama Seu Zé" reserva-se o direito de, a qualquer tempo, modificar, suspender ou descontinuar temporariamente ou permanentemente o Serviço ou parte dele, sem aviso prévio ao Usuário.
6. RESPONSABILIDADE
6.1. O Usuário é responsável por qualquer dano causado ao Prestador de Serviços ou a terceiros durante a execução do serviço, bem como por qualquer infração à legislação em vigor, cabendo direito de regresso da plataforma caso venha a ser responsabilizada pelas autoridades administrativas, reguladoras ou judiciais.
6.2. A CHAMA SEU ZÉ não será responsabilizada por problemas técnicos relacionados ao acesso ao aplicativo ou falhas nos serviços de telecomunicação que afetem o uso dos Serviços.
6.3. A Plataforma não será responsável por quaisquer danos indiretos, incidentais, especiais, punitivos ou emergentes, incluindo lucros cessantes, perda de dados, danos morais ou patrimoniais decorrentes ou relacionados ao uso dos serviços, ainda que tenha sido previamente informada sobre a possibilidade desses danos. A plataforma não se responsabiliza por qualquer dano, obrigação ou prejuízo resultante de: (i) seu uso dos serviços ou incapacidade de acessá-los; ou (ii) qualquer interação ou relação entre Usuário e qualquer prestador autônomo de serviços, mesmo que a CHAMA O SEU ZÉ tenha sido alertada sobre a possibilidade desses danos.
6.3.1. Além disso, a CHAMA O SEU ZÉ não será responsável por atrasos ou falhas originadas de causas fora de seu controle razoável, nem pela qualidade, integridade ou pontualidade dos serviços ou produtos oferecidos por prestadores independentes.
6.3.2. Os serviços da CHAMA O SEU ZÉ podem ser usados para solicitar e agendar serviços como transporte, entrega de produtos, prestação de serviços e reparos domésticos ou outros que venham a ser oferecidos no aplicativo, fornecidos por prestadores autônomos. No entanto, a CHAMA O SEU ZÉ não assume responsabilidade sobre a execução desses serviços, salvo nos casos expressamente previstos nestes Termos. Isso inclui, mas não se limita a rotas adotadas pelos prestadores, qualidade dos produtos entregues ou quaisquer itens perdidos durante a prestação dos serviços.
6.3.3. Os limites de responsabilidade desta cláusula não têm a intenção de restringir direitos do Usuário que, de acordo com a legislação aplicável, não possam ser limitados ou modificados.

7.  ATENDIMENTO E SUPORTE AO USUÁRIO
A CHAMA O SEU ZÉ disponibiliza ao Usuário canais de atendimento para suporte técnico, dúvidas e solicitações relacionadas à utilização dos Serviços. O suporte pode ser acessado pelos seguintes meios:
7.1	Suporte via Aplicativo: O Usuário poderá acessar o suporte diretamente pelo aplicativo, por meio da seção “Ajuda” disponível no menu principal. Nessa área, será possível enviar mensagens sobre problemas técnicos, dificuldades com a utilização dos serviços, e solicitar assistência relacionada às suas interações com prestadores autônomos.
7.2	Atendimento Telefônico: O Usuário terá à disposição uma linha direta de atendimento ao cliente, que funcionará em dias úteis, de segunda a sexta-feira, das 9h às 18h (horário de Brasília). O número será disponibilizado na seção “Contato” do aplicativo.

8. DO PAGAMENTO DO SERVIÇO DE TRANSPORTE INDIVIDUAL

8.1. Preço. O Usuário entende que o Prestador de Serviço, poderão ser cobrados (“Preço(s)”). O pagamento do Preço é feito diretamente pelo Usuário, por uma das modalidades de pagamento disponíveis autorizadas previamente pelo prestador de serviço: (i) em sua própria máquina de cartão de débito ou crédito vinculada a conta da plataforma; e/ou (ii) por meio do próprio Aplicativo. A CHAMA O SEU ZÉ não se responsabiliza por qualquer outra modalidade de pagamento, exceto por meio do próprio Aplicativo nas modalidades acima referidas.
8.1.2.O Usuário está ciente e concorda com a possibilidade de variação do preço público, sem prejuízo ao Preço dos Serviços, que constar em seu recibo em razão das diferentes metodologias eventualmente adotadas por certos municípios para a apuração do seu valor e para os diferentes serviços, bem como por diferentes prestadores de serviços disponíveis.
8.1.2 Taxa de Cancelamento. O Usuário poderá optar por cancelar sua solicitação dos Serviços de Parceiros a qualquer momento antes da chegada desse prestador Parceiro ao local de destino, caso em que poderá incidir uma taxa de cancelamento (“Taxa de Cancelamento”). A Taxa de Cancelamento será destinada ao Prestador Parceiro como forma de ressarcimento pelo seu deslocamento e tempo de espera.
9. Conteúdo Fornecido pelo Usuário
9.1 A CHAMA O SEU ZÉ poderá, a seu exclusivo critério, permitir que o Usuário apresente, carregue, publique ou, de qualquer forma, disponibilize conteúdo e informações de texto, áudio, vídeo ou outros formatos por meio dos Serviços, incluindo comentários, feedbacks, sugestões, solicitações de suporte e participações em promoções e concursos ("Conteúdo de Usuário"). 
Todo o Conteúdo de Usuário fornecido permanecerá de propriedade do Usuário. No entanto, ao fornecer qualquer Conteúdo para a “Chama Seu Zé”, o Usuário concede à empresa e suas afiliadas uma licença global, perpétua, irrevogável, transferível, isenta de royalties e com direito de sublicenciar para usar, copiar, modificar, criar obras derivadas, distribuir, publicar, exibir publicamente e explorar de qualquer forma esse conteúdo em todos os formatos e canais de distribuição conhecidos ou que venham a ser desenvolvidos, sem a necessidade de nova autorização ou pagamento ao Usuário.

O Usuário declara e garante que:  
(i) É o único e exclusivo proprietário do Conteúdo de Usuário ou possui todos os direitos, licenças e autorizações necessárias para conceder à CHAMA O SEU ZÉ a licença mencionada; e  
(ii) O Conteúdo de Usuário e seu envio, publicação ou disponibilização não infringem, violam ou se apropriam indevidamente de qualquer direito de propriedade intelectual, privacidade ou publicidade de terceiros, nem violam qualquer lei ou regulamento aplicável.
O Usuário concorda em não enviar ou disponibilizar conteúdo que seja difamatório, calunioso, injurioso, violento, obsceno, pornográfico, ilegal ou ofensivo, a critério exclusivo da CHAMA O SEU ZÉ. A empresa se reserva o direito, mas não é obrigada, a monitorar, revisar ou remover qualquer Conteúdo de Usuário, a qualquer momento e por qualquer motivo, sem aviso prévio ao Usuário, podendo remover os conteúdos que desrespeitem suas políticas, com contraditório diferido.

10. DA RESCISÃO 
10.1. O presente contrato poderá ser rescindido a qualquer momento por ambas as partes, sem necessidade de justificativa, mediante aviso prévio de 15 (quinze) dias úteis, enviado por escrito via e-mail cadastrado no sistema. 
10.2. Em caso de descumprimento de qualquer obrigação prevista neste Termo, a parte lesada poderá rescindir o contrato imediatamente, sem necessidade de aviso prévio, sem prejuízo das sanções legais aplicáveis e da cobrança de eventuais danos causados. 
10.3. A rescisão contratual não exime o Usuário das obrigações financeiras pendentes.

11. DA PROPRIEDADE INTELECTUAL 
11.1. Todos os direitos de propriedade intelectual, incluindo, mas não se limitando a marcas, logotipos, layouts, sistemas, produtos e conteúdos divulgados no aplicativo e no site da CHAMA O SEU ZÉ são de titularidade exclusiva da empresa, sendo vedada a reprodução, distribuição ou qualquer tipo de utilização sem autorização prévia e expressa da plataforma. 
11.2. A utilização indevida dos referidos direitos será passível de penalidades civis e criminais nos termos das leis aplicáveis, sendo permitido o desconto direto através de cartões de crédito/débito cadastrados na plataforma.

12. DA LEGISLAÇÃO E FORO APLICÁVEL 
12.1. Fica eleito o foro da Comarca de São José do Rio Preto, Estado de São Paulo, para dirimir quaisquer dúvidas ou controvérsias oriundas deste Termo, com renúncia expressa a qualquer outro, por mais privilegiado que seja.
13. DISPOSIÇÕES GERAIS 
13.1. Este Termo constitui o entendimento integral entre as partes, prevalecendo sobre quaisquer outros acordos, entendimentos ou negociações anteriores, ressalvados as disposições anexas complementares, que definirão especificidades pertinentes. 
13.2. Caso qualquer disposição deste Termo seja considerada inválida ou inexequível, as demais disposições permanecerão em pleno vigor e efeito, devendo ser interpretadas sistematicamente.
13.3. A tolerância quanto ao descumprimento de qualquer obrigação prevista neste Termo não constituirá renúncia, novação ou alteração das disposições pactuadas, prevalecendo o acordo escrito sobre qualquer outra tratativa verbal ou de outro modo informal.

São José do Rio Preto/SP

ASSINATURA DIGITAL


ANEXO 1

TERMOS GERAIS DE POLÍTICA DE DADOS

1. INTRODUÇÃO
Ao utilizar o aplicativo CHAMA O SEU ZÉ, você nos confia seus dados pessoais. Nosso compromisso é garantir a proteção dessas informações e este termo descreve os dados pessoais que coletamos, como eles são usados e compartilhados, além de suas opções em modificar, cancelar o compartilhamento ou fazer apontamentos.

2. VISÃO GERAL
A. Escopo
1.	Este termo se aplica a todos os usuários do aplicativo CHAMA O SEU ZÉ e seus serviços, incluindo o Marketplace, a Universidade Corporativa e quaisquer outras funcionalidades disponibilizadas.
2.	Ele abrange tanto os Clientes Finais, que utilizam o aplicativo para solicitar serviços ou adquirir produtos, quanto os Prestadores de Serviços, assinantes ou não, que oferecem serviços de maneira individual por meio da plataforma.
3.	As práticas de privacidade do CHAMA O SEU ZÉ seguem as legislações aplicáveis do Brasil, incluindo, mas não se limitando, a Lei Geral de Proteção de Dados Pessoais (LGPD).

B. Definições
1.	Dados Pessoais: Qualquer informação relacionada a uma pessoa natural identificada ou identificável que tenha acesso a plataforma, independente do objetivo;
2.	Dados Sensíveis: Dados pessoais que revelem origem racial ou étnica, convicção religiosa, opinião política, entre outros previstos na LGPD.
3.	Tratamento de Dados: Toda operação realizada com dados pessoais, como coleta, armazenamento, uso, compartilhamento, entre outros.
4.	Controlador e Operador: O controlador é quem toma decisões sobre o tratamento de dados, enquanto o operador apenas executa as ordens do controlador;

C. Princípios Da Proteção De Dados
1.	Finalidade: Tratamento de dados será realizado para propósitos específicos e legítimos, destinados à operação da plataforma e garantir a conformidade dos serviços, informados de maneira clara ao usuário. Outrossim, serão compartilhados com stakeholders e demais parceiros de negócios cadastrados na plataforma mesmo após a opção do usuário de excluir sua conta, pelo período de 48 (quarenta e oito meses) com a finalidade de pesquisa de mercado.
2.	Necessidade: A coleta de dados será limitada ao mínimo necessário para a realização dos serviços, sendo que qualquer exigência adicional será previamente informada aos usuários, prestador de serviço ou não, para as finalidades nela especificadas.
3.	Transparência: O tratamento dos dados será feito de forma transparente, com todas as informações sobre como os dados são utilizados sendo disponibilizadas aos usuários.
4.	Segurança e Prevenção: Medidas de segurança apropriadas serão adotadas para proteger os dados de acessos não autorizados e de situações acidentais ou ilícitas, sendo que qualquer espécie de vazamento ou incidente serão comunicados imediatamente as pessoas afetadas.

D. Consentimento Dos Usuários
1.	Coleta e Consentimento: O tratamento de dados pessoais pelo CHAMA O SEU ZÉ dependerá de consentimento livre, informado e inequívoco dos usuários, conforme a assinatura dos termos ao acessar a plataforma, quando aplicável para situações específicas assim definidas em lei e nas hipóteses definidas nesta política.
2.	Revogação do Consentimento: O usuário pode revogar o consentimento a qualquer momento, sendo assegurada a exclusão de seus dados conforme suas preferências, exceto nos casos em que a manutenção for exigida por lei. No presente caso, a Plataforma se reserva no direito de manter os dados dos usuários e prestadores de serviço após 48 (quarenta e oito) meses de sua saída, para fins de pesquisa de mercado. 

3. COLETA E USO DE DADOS
A. Dados Que Coletamos
A CHAMA O SEU ZÉ coleta as seguintes categorias de dados, assim definidas:
Dados informados pelos usuários:
1.	Informações de conta, como nome, e-mail, número de telefone, endereço, dados de pagamento e geolocalização;
2.	Para Prestadores de Serviço, é possível coletar informações adicionais, como documentos de identificação e comprovação de capacidade profissional, de acordo com as exigências de segurança e conformidade a depender da espécie de serviço prestado.
Dados criados durante o uso dos nossos serviços:
1.	Dados de localização aproximada ou precisa, quando os serviços estão em uso. 
2.	Informações sobre transações realizadas, como valores pagos, produtos comprados, serviços solicitados e histórico de interações no aplicativo.
3.	Dados fornecidos por parceiros comerciais do "Chama o Seu Zé" ou fontes públicas, quando relevante para a prestação do serviço.

4. DIREITOS DOS TITULARES DE DADOS
A.	Direito de Acesso: O usuário tem o direito de obter confirmação sobre a existência de tratamento de seus dados pessoais e acesso a eles, podendo fazer apontamentos.
B.	Direito de Correção: O usuário pode solicitar a correção de dados incompletos, inexatos ou desatualizados.
C.	Direito de Portabilidade: O usuário tem direito de receber seus dados pessoais em formato estruturado e de uso comum, podendo transferi-los a outro controlador.
D.	Direito à Eliminação de Dados: O usuário pode solicitar a exclusão de seus dados pessoais tratados com base em consentimento, exceto quando o tratamento for necessário para cumprimento de obrigação legal ou regulatória, ressalvado o prazo adicional de 48 (quarenta e oito) meses que a plataforma utilizará para fins de pesquisa de mercado e compartilhamento com stakeholders.
E.	Direito de Oposição: O usuário pode se opor ao tratamento de dados realizado com base em uma das hipóteses de dispensa de consentimento, desde que não haja justificativa legítima para o tratamento.

5. COOKIES E TECNOLOGIAS DE RASTREAMENTO
A.	Uso de Cookies: O CHAMA O SEU ZÉ poderá utilizar cookies e tecnologias de rastreamento para melhorar a experiência do usuário na plataforma.
B.	Opção de Controle: O usuário poderá configurar seu navegador ou dispositivo para bloquear cookies ou alertar sobre seu uso, mas isso pode impactar a funcionalidade do aplicativo.

6. TERMOS E CONDIÇÕES ESPECÍFICOS PARA MENORES DE IDADE
A.	Tratamento de Dados de Menores: O tratamento de dados pessoais de menores de 18 anos será realizado apenas com o consentimento específico e destacado de um dos pais ou responsáveis legais.
B.	Finalidade: O tratamento de dados de menores será limitado ao estritamente necessário para a prestação de serviços da plataforma.

7. SEGURANÇA DOS DADOS
A.	Medidas Técnicas e Administrativas: O CHAMA O SEU ZÉ adotará medidas técnicas e administrativas para proteger os dados pessoais contra acessos não autorizados, perda, destruição ou qualquer forma de tratamento inadequado.
B.	Notificação de Incidentes de Segurança: Em caso de incidentes de segurança que possam gerar risco ou dano relevante aos usuários, o CHAMA O SEU ZÉ notificará a Autoridade Nacional de Proteção de Dados (ANPD) e os titulares dos dados afetados.

8. ATUALIZAÇÃO E REVISÃO DESTA POLÍTICA
A.	Periodicidade de Revisão: O CHAMA O SEU ZÉ revisará periodicamente este termo para garantir sua conformidade com novas normas e práticas de mercado.
B.	Comunicação de Alterações: Quaisquer alterações significativas nesta política serão comunicadas previamente aos usuários, garantindo-lhes a oportunidade de avaliar e, quando necessário, consentir com os novos termos.

9. COMO USAMOS OS DADOS
A CHAMA O SEU ZÉ utiliza dados para facilitar o acesso aos serviços oferecidos na plataforma de forma eficiente, segura e personalizada. Esses dados também são utilizados para:
A.	Melhorar a segurança dos usuários e prestadores de serviços;
B.	Fornecer suporte ao cliente;
C.	Realizar pesquisa e desenvolvimento;
D.	Viabilizar a comunicação entre prestadores de serviços e clientes;
E.	Fins de marketing e publicidade;
F.	Enviar comunicações não relacionadas a marketing, ainda que de forma automática;
G.	Atender a exigências legais;
H.	Pesquisa de mercado, mesmo que após a saída do usuário da Plataforma, pelo prazo de 48 (quarenta e oito) meses, incluindo o compartilhamento com stakeholders cadastrados na plataforma.

11. PRESTAÇÃO DOS SERVIÇOS
A CHAMA O SEU ZÉ utiliza os dados para operar, personalizar, manter e melhorar seus serviços, garantindo o bom funcionamento da plataforma. Entre os usos incluem-se:
A.	Criação e atualização de contas de assinantes, quando for o caso, e não assinantes.
B.	Facilitação de interações entre prestadores de serviços e clientes:
C.	Utilização de dados de localização para sugerir prestadores de serviços próximos aos clientes e facilitar a escolha de profissionais.
D.	Compartilhamento de informações essenciais, como nome e informações de contato, para facilitar a comunicação e a prestação de serviços.
E.	A alocação dos prestadores de serviços é feita com base em dados como proximidade geográfica, perfil do prestador, avaliações e histórico de atendimento.
F.	Cálculo de preços e estimativas com base nos dados fornecidos, como localização, tipo de serviço, e outras variáveis não pessoais, como horários e demanda.
G.	Processamento de pagamentos e transações realizadas entre clientes e prestadores por meio da plataforma, permitindo o pagamento eletrônico de maneira segura.
H.	Personalização da experiência do usuário, por exemplo, sugerindo prestadores de serviços preferidos ou localizados próximos de acordo com os dados de uso da plataforma.
I.	Geração de relatórios e recibos, além de atualizações sobre os serviços, termos e políticas da CHAMA O SEU ZÉ.
A plataforma realiza essas atividades para cumprir os termos do contrato com seus assinantes e para melhorar continuamente os serviços, conforme os interesses legítimos dos prestadores e usuários.

12. SEGURANÇA, PREVENÇÃO DE FRAUDES E PROTEÇÃO
A CHAMA O SEU ZÉ utiliza dados para garantir a segurança e integridade da plataforma e dos seus usuários, como:
A.	Verificação de identidade dos prestadores de serviços, garantindo que os profissionais cadastrados atendam aos requisitos exigidos, como histórico de atuação e avaliação de qualificações.
B.	Análise de comportamento na plataforma, utilizando dados como histórico de serviços prestados, localização e transações para detectar possíveis atividades fraudulentas ou uso indevido.
C.	Confirmação de identidade por meio de documentos e fotografias quando necessário, especialmente para assegurar a autenticidade dos prestadores e evitar a criação de múltiplas contas por um mesmo usuário.
Essas medidas são adotadas para garantir a segurança da plataforma e dos usuários, e podem resultar na desativação de contas em caso de irregularidades.

13. COMPARTILHAMENTO DE DADOS COM TERCEIROS
A.	Parcerias e Provedores de Serviços: O CHAMA O SEU ZÉ poderá compartilhar dados com terceiros para a prestação de serviços ou fornecimento de produtos, sempre garantindo que esses terceiros sigam as mesmas normas de proteção de dados e finalidades específicas aqui definidas, inclusive após a saída do usuário da plataforma, pelo prazo de 48 (quarenta e oito meses) para fins de pesquisa de mercado.
B.	Consentimento para Compartilhamento: Quando necessário, o consentimento específico para o compartilhamento de dados com terceiros será solicitado ao usuário, ficando desde já autorizado em relação a todos os parceiros que forem prestadores de serviço ou produtos cadastrados na plataforma;

14. TRANSFERÊNCIA INTERNACIONAL DE DADOS: 
A plataforma poderá transferir dados pessoais coletados para outros países, desde que eles estejam sujeitos a um nível de proteção adequado, seja para prestadores de serviços, clientes finais localizados no exterior ou para parceiros comerciais internacionais, e essas transferências ocorrerão apenas nos seguintes casos:
A.	Países com Nível Adequado de Proteção: A transferência será permitida para países ou organismos internacionais que proporcionem um grau de proteção de dados pessoais considerado adequado pela Autoridade Nacional de Proteção de Dados (ANPD), em conformidade com a legislação brasileira.
B.	Garantias Contratuais: Caso os dados sejam transferidos para países que não oferecem um nível adequado de proteção de dados, a transferência será realizada com base em garantias apropriadas, como cláusulas contratuais específicas ou acordos internacionais, conforme aprovado pela ANPD, que assegurem a proteção e os direitos dos titulares dos dados e tenham autorização específica do usuário.
C.	Consentimento Específico: Nos casos em que o nível de proteção no país de destino não seja considerado adequado e não houver garantias apropriadas, a transferência de dados será realizada mediante o consentimento explícito e informado do titular dos dados, após ele ser informado claramente sobre os riscos envolvidos na transferência internacional.
D.	Exceções Legais: Em determinadas situações, a transferência internacional de dados poderá ocorrer sem o consentimento do titular, como nos casos em que a transferência seja necessária para:
•	Execução de contrato entre o titular e o controlador, ou para a realização de procedimentos preliminares relacionados a contrato;
•	Cumprimento de obrigação legal ou regulatória pelo controlador;
•	Proteção da vida ou segurança física do titular ou de terceiros;
•	Execução de políticas públicas, desde que prevista em lei ou regulamento;
•	Exercício regular de direitos em processos judiciais, administrativos ou arbitrais;
•	Salvaguarda do crédito, conforme a legislação aplicável.
E.	Responsabilidades e Transparência: A CHAMA O SEU ZÉ se compromete a adotar medidas adequadas para assegurar que a transferência internacional de dados seja realizada de forma segura e transparente. Sempre que necessário, disponibilizaremos informações detalhadas sobre os países ou organizações internacionais destinatários dos dados e as garantias adotadas para proteger esses dados.
F.	Notificação de Transferências: A CHAMA O SEU ZÉ notificará previamente os usuários sobre qualquer transferência internacional de dados pessoais, exceto quando essa comunicação for dispensada por força de lei ou regulamento. A notificação incluirá o motivo da transferência, os destinatários dos dados e as garantias implementadas para a proteção dos dados pessoais.
 15. PESQUISA E DESENVOLVIMENTO
Os dados são utilizados pela CHAMA O SEU ZÉ para:
A.	Análise de dados e melhoria contínua dos serviços, incluindo o desenvolvimento de novos recursos e funcionalidades com base nas necessidades dos usuários e para pesquisa de mercado, mesmo após o desligamento do usuário, até o prazo de 48 (quarente e oito) meses.
B.	Testes e aprimoramento da experiência do usuário, com foco em tornar a plataforma mais intuitiva e eficaz.
Essas atividades são realizadas com base nos interesses legítimos da CHAMA O SEU ZÉ em oferecer um serviço de alta qualidade.
 
16. COMUNICAÇÃO ENTRE ASSINANTES E CLIENTES
A plataforma possibilita a comunicação direta entre prestadores e clientes, utilizando dados como:
A.	Nome e contato do prestador para facilitar o agendamento e execução dos serviços solicitados.  
B.	Comunicação via mensagens ou ligações para confirmar serviços ou esclarecer dúvidas.
Essas ações são fundamentais para garantir o bom andamento dos serviços e estão de acordo com os termos do contrato com os assinantes, sendo que o vazamento de dados pelas partes deverão ser notificados a plataforma, bem como a responsabilização pessoal recairá a quem der causa direta ao resultado danoso em concreto ou potencial.

 17. MARKETING E PUBLICIDADE
A CHAMA O SEU ZÉ pode utilizar dados coletados para:
A.	“Enviar comunicações de marketing”, como promoções, novos serviços e descontos por meio de e-mails, SMS, notificações no aplicativo ou outros canais de comunicação.
B.	“Exibir anúncios personalizados” de acordo com o histórico de uso do assinante, localização e interesses observados.

Esses anúncios podem ser relacionados tanto aos serviços oferecidos pela plataforma quanto por parceiros comerciais, e visam promover serviços relevantes para os usuários.

 18. COMUNICAÇÕES SEM FINS DE MARKETING
Além de campanhas promocionais, a “Chama o Seu Zé” pode usar dados para:
A.	Enviar pesquisas e comunicações sobre melhorias na plataforma ou sobre a experiência do usuário, com foco no aprimoramento dos serviços.
Essas comunicações são realizadas de acordo com os interesses legítimos da CHAMA O SEU ZÉ em manter os usuários informados sobre o funcionamento da plataforma e oportunidades de aprimoramento.

 19. PROCEDIMENTOS E EXIGÊNCIAS LEGAIS
A “Chama o Seu Zé” pode utilizar dados para:
A.	Cumprir obrigações legais e regulatórias, como responder a processos judiciais, reclamações de usuários, ou atender solicitações de autoridades.
Essas atividades são realizadas para garantir que a CHAMA O SEU ZÉ opere de forma segura e em conformidade com a legislação aplicável.

A CHAMA O SEU ZÉ retém os dados do usuário pelo tempo necessário para as finalidades descritas neste termo, que variam de acordo com o tipo de dados, a categoria de usuário a quem os dados se referem, as finalidades para as quais coletamos os dados e se eles devem ser retidos após uma solicitação de exclusão de conta para os fins descritos abaixo. Por exemplo, retemos dados para:
A.	por toda a vida útil das contas dos usuários, se esses dados forem necessários para prestar nossos serviços. P.ex., dados da conta.
B.	por 7 anos, se necessário para cumprir as exigências fiscais decorrentes da prestação de serviços.
C.	Por 48 (quarenta e oito meses) após o desligamento da plataforma, com a finalidade de pesquisa de mercado, ai incluindo stakeholders cadastrados na plataforma.

Os usuários podem solicitar a exclusão da conta a qualquer momento pelo app, no menu "Configurações -> Privacidade", ou pelo site da “Chama seu Zé”. (colocar passo a passo de como excluir). 

Após solicitações de exclusão da conta, serão excluídos inclusive os dados, exceto em caso de necessidade para fins de segurança, prevenção de fraudes ou conformidade com requisitos legais, ou devido a problemas relacionados à conta do usuário (como um crédito pendente, reclamação ou disputa não resolvida). 

20. OPÇÕES E TRANSPARÊNCIA
A CHAMA SEU ZÉ permite que os usuários acessem e/ou controlem os dados que coleta, inclusive por meio de: 
A.	Configurações de privacidade
B.	Permissões do dispositivo
C.	Páginas de avaliação no app
D.	Opções de marketing e publicidade

21. FORO E LEGISLAÇÃO APLICÁVEL
A.	Legislação Aplicável: Este termo será regido pelas leis da República Federativa do Brasil, em especial pela LGPD.
B.	Foro Competente: Fica eleito o foro da Comarca de São Paulo, Estado de São Paulo, para dirimir quaisquer questões decorrentes deste termo, com renúncia a qualquer outro, por mais privilegiado que seja

São José do Rio Preto, 09 de outubro de 2024



        </p>
    )
}

export default TermoCliente;