import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import './serviçoemandamento.css';
import BackButton from "../../components/botãodevoltar"

const socket = io('https://app.chamaoseuze.com.br/'); // Substitua pela URL correta do seu servidor

const ServicoEmAndamento = () => {
  const { id } = useParams(); // Pega o ID da URL
  const [serviceDetails, setServiceDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const compareDates = (date1, date2) => {
    const d1 = new Date(date1).toISOString().split('T')[0];
    const d2 = new Date(date2).toISOString().split('T')[0];
    return d1 === d2;
  };



  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await axios.get(`https://app.chamaoseuze.com.br/pedidos/${id}`);

        setServiceDetails(response.data);
      } catch (err) {
        setError('Erro ao carregar os detalhes do serviço.');
      } finally {
        setLoading(false);
      }
    };

    fetchServiceDetails();
  }, [id]);

  const handleCancelOrder = async () => {
    const atualDate = new Date();
    const orderDate = serviceDetails.time

    const currentDate = new Date();
    const serviceDate = new Date(serviceDetails.date);






    if (compareDates(currentDate, serviceDate) && parseInt(orderDate.split(':')[0]) - atualDate.getHours() < 2) {
      await axios.post('https://app.chamaoseuze.com.br/atualizar-pedido', {
        id: id,
        status: 'Cancelado',
        provider: serviceDetails.provider,
        idprestador: serviceDetails.idprestador,
        preco: 15
      });
      navigate(`/payment-gateway-cancel/${id}`);
    } else if (!compareDates(currentDate, serviceDate) || parseInt(orderDate.split(':')[0]) - atualDate.getHours() > 2) {
      await axios.post('https://app.chamaoseuze.com.br/atualizar-pedido', {
        id: id,
        status: 'Cancelado',
        provider: serviceDetails.provider,
        idprestador: serviceDetails.idprestador,
        preco: 0
      })

      setModal(true)
    }


  }


  useEffect(() => {
    socket.on('status-pedido-atualizado', ({ id: pedidoId, status }) => {
      if (pedidoId === Number(id) && status === 'Concluído') {
        navigate(`/payment-gateway/${id}`);
      } else if (pedidoId === Number(id) && status === 'Cancelado') {
        setModal(true)
      }
    });

    return () => {
      socket.off('status-pedido-atualizado');
    };
  }, [id, navigate]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!serviceDetails) {
    return <div>Nenhum detalhe de serviço encontrado.</div>;
  }

  return (
    <div className="ongoing-service-container">
      <BackButton />
      <h1>Serviço em Andamento</h1>
      <div id="service-details" className="service-details">
        {modal && (
          <div className="modal">
            <div className="modal-content">
              <h2>Seu atendimento foi cancelado</h2>
              <p>Você cancelou com mais de 2(Duas) horas de antecedencia deixando insento da taxa de cancelamento</p>
              <button onClick={(e) => navigate(`/MapaPage/${serviceDetails.idcliente}`)}>
                Voltar para o Menu
              </button>
            </div>
          </div>
        )}
        <div className="service-item">
          <h2>{serviceDetails.nome_servico}</h2>
          <p><strong>Categoria:</strong> {serviceDetails.categoria}</p>
          <p><strong>Preço:</strong> {serviceDetails.preco}</p>
          <p><strong>Cliente:</strong> {serviceDetails.cliente}</p>
          <p style={{ display: 'flex', alignItems: "center" }}><strong>Prestador:</strong>{serviceDetails.provider}<img style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover' }} src={`https://app.chamaoseuze.com.br/api/imagem/${serviceDetails.idprestador}`} alt="" /></p>
          <p><strong>Status:</strong> {serviceDetails.status}</p>
          <button onClick={handleCancelOrder}>Cancelar</button>
          <div className="loading-bar-container">
            <div className="loading-bar"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicoEmAndamento;
