const SimpleButton = ({onClick, style, children}) =>{
 return(
    <button
     onClick={onClick}
     style={style}
    >
        {children}
    </button>
 )
}

export default SimpleButton;