import { useNavigate, useParams } from 'react-router-dom';
export const BackButtonAdmin = () =>{
    const {id, token} = useParams()
    const navigate = useNavigate();
  
    const handleClick = () => {
      navigate(`/Principal-Administrador/${token}/${id}`); // Redireciona para a página principal
    };
    
    return(
        <button className="back-button" onClick={handleClick}>
            Voltar
        </button>
    )
}