import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './perfil.css';
import BackButton from '../../components/botãodevoltarP';

import Simplebutton from '../../components/simplebutton';
import TermoPrestadorNaoAssinante from '../cadastro/TermoPrestadorNãoAssinante';
import { Field, Form, Formik } from 'formik';

const Profile = () => {
  const id = localStorage.getItem("userId");
  const [userData, setUserData] = useState(null);
  const [profileImage, setProfileImage] = useState(); // Caminho padrão para a imagem do perfil
  const [mediaAvaliacoes, setMediaAvaliacoes] = useState(0); // Novo estado para a média das avaliações
  const [image, setImage] = useState(null);
  const [isModal, setIsModal] = useState(false)
  const [isModal2, setIsModal2] = useState(false)
  const [isModal3, setIsModal3] =useState(false)

  const [showDescriptionInput, setShowDescriptionInput] = useState(false);
  const [descricao, setDescricao] = useState('');

  const handleModal = () =>{
    setIsModal((prev) => !prev)
  }

  const handleModal2 = () =>{
    setIsModal2((prev) => !prev)
  }

  const deleteAccount = async () =>{
    try {
      const response = await axios.post(`https://app.chamaoseuze.com.br/DeleteProfileProvider/${id}`)
      setIsModal3(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Busca os dados do prestador com base no ID
      const response = await axios.get(`https://app.chamaoseuze.com.br/prestador/${id}`);
        setUserData(response.data);
        if (response.data.profileImage) {
          setProfileImage(response.data.profileImage);
        }
      } catch (error) {
        console.error('Erro ao buscar dados do prestador:', error);
      }
    };

    const fetchMediaAvaliacoes = async () => {
      try {
        // Busca a média das avaliações do prestador
        const response = await axios.get(`https://app.chamaoseuze.com.br/avaliacoes/prestador/${id}/media`);
        setMediaAvaliacoes(response.data.mediaAvaliacoes);
      } catch (error) {
        console.error('Erro ao buscar média das avaliações:', error);
      }
    };


    fetchUserData();
    fetchMediaAvaliacoes();
    
  }, [id]);

 

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file)
    const reader = new FileReader();

    reader.onload = (e) => {
      setProfileImage(e.target.result);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const UpdateImage = async () => {
  
        if(image !== null ){
          
          const logoBase64 = await ToBase64(image)
          
          try {
              const response = await axios.post("https://app.chamaoseuze.com.br/api/updateProfileImage", { Logo: logoBase64, id: id})
              return response
          } catch (error) {
              console.error(error.message || error.data)        
          }
        }
     }

    const ToBase64 = (file) => {

        
        if(file !== null){
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);  // Lê o arquivo como URL de dados (Base64)
                reader.onload = () => resolve(reader.result);  // Retorna a string Base64 quando a leitura for concluída
                reader.onerror = (error) => reject(error);  // Retorna erro se falhar
            });
        }else{
            return null
        }
    }
  
    UpdateImage()
  },[image])

  useEffect(() => {
    
    const fetchAverageTime = async()=>{
      try {
        const response = await axios.get(`https://app.chamaoseuze.com.br/pegarTempoMedio/${id}`)
      } catch (error) {
        console.error(error)        
      }
    }

    fetchAverageTime()
  },[])


  



  // Set da descrição
  const toggleDescriptionInput = () => {
    setShowDescriptionInput(!showDescriptionInput);
  };

  const saveDescription = async (value) => {
    try {
      
      const response = await axios.post(`https://app.chamaoseuze.com.br/providerDescription/${id}`, value);
      if(response.data.message = 'Descrição atualizada com sucesso!'){
        setShowDescriptionInput(false)
      }
      
    } catch (error) {
      console.error('Erro ao salvar descrição:', error.response ? error.response.data : error.message);
    }
  };

  if (!userData) return null;

  return (
    <div className="containerp">
      <BackButton /> <Simplebutton onClick={handleModal} style={{weigth:'600', padding:'12px 20px'}}>{'Configuração'}</Simplebutton>
      {isModal &&(
        <div className='modal'>
          <div className='modal-content' style={{display:'flex',flexDirection: 'column', position: 'fixed', left: 0, height:'100%'}}>
            <Simplebutton onClick={handleModal} style={{width: '30px'}}>x</Simplebutton>
            <h2>
              Configurações
            </h2>
            <Simplebutton onClick={handleModal2}>Termos de uso e privacidade</Simplebutton>
            {isModal2 &&(
              <div className="modal" style={{alignItems: 'baseline'}}>
                <div className="modal-content" style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  
                  <div className="termos">
                    <TermoPrestadorNaoAssinante/>
                  </div>
                  <Simplebutton onClick={handleModal} style={{width:'200px'}}>Concluir</Simplebutton>
                </div>
              </div>
            )}
            <Simplebutton onClick={deleteAccount} style={{backgroundColor: 'red'}}>Excluir Conta</Simplebutton>
            {isModal3 &&(
              <div className="modal">
                <div className="modal-content">
                  <h2>Conta excluida com sucesso!</h2>
                  <a href="/">Ok</a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <h1>Meu Perfil</h1>
      <div className="profile-header">
        <div className="profile-picture">
          <img src={`https://app.chamaoseuze.com.br/api/imagem/${id}`} alt="Perfil" />
          <input
            type="file"
            id="upload-photo-btn"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <label htmlFor="upload-photo-btn" className="upload-btn">Alterar Foto</label>
        </div>
        <div className="profile-infoP">
          <div className="editable">
            <label htmlFor="nome">Nome do Prestador:</label>
            <input
              type="text"
              id="nome"
              value={userData.nomeCompleto || ''}
              readOnly
            />
          </div>
          <div className="editable">
            <label htmlFor="servico">Serviço Prestado:</label>
            <select
              id="servico"
              value={userData.servico || ''}
              disabled
            >
              <option value="eletricista">Eletricista</option>
              <option value="encanador">Encanador</option>
              <option value="pintor">Pintor</option>
              {/* Adicione mais opções conforme necessário */}
            </select>
          </div>
          <div className="editable">
            <label htmlFor="endereco">Endereço:</label>
            <input
              type="text"
              id="endereco"
              value={userData.endereco || ''}
              readOnly
            />
          </div>
          <div className="editable">
            <label htmlFor="mediaAvaliacoes">Média das Avaliações:</label>
            <input
              type="text"
              id="mediaAvaliacoes"
              value={mediaAvaliacoes.toFixed(2) || '0.00'}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="profile-description">
        <h2>Descrição</h2>
        <p>{userData.descript || 'Descrição não disponível'}</p>
        <button className='button-perfil-prestador' onClick={toggleDescriptionInput}>
          <i className="fas fa-edit"></i> Editar Descrição
        </button>
        {showDescriptionInput && (
          <div className="description-edit">
            <Formik
              initialValues={{
                "descript": ''
              }}
              onSubmit={saveDescription}
              >
                <Form>
                  <Field
                    type='textArea'
                    name='descript'
                    placeholder="Adicione sua descrição aqui!"
                  />
                  <button>Salvar</button>
                </Form>
              </Formik>
          </div>
        )}
      </div>
      <div className="profile-achievements">
        <h2>Troféis e Certificados</h2>
        <div className="achievements">
          {/* Exemplo de troféus e certificados */}
          <div className="achievement">
            <i className="fas fa-trophy"></i>
            <p>Prêmio de Melhor {userData.servico || 'Prestador'}</p>
          </div>
          <div className="achievement">
            <i className="fas fa-certificate"></i>
            <p>Certificado de Excelência em Atendimento</p>
          </div>
          {/* Adicione mais conquistas conforme necessário */}
        </div>
      </div>
    </div>
  );
};

export default Profile;