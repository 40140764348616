const TermoPrestadorNaoAssinante = () =>{
    return(
        <p>
            TERMOS E CONDIÇÕES GERAIS DE PROVIMENTO DE LICENÇAS, ACESSOS, PRODUTOS E SERVIÇOS – ("TCGP") - SOFTWARE CHAMA O SEU ZÉ

CONSIDERANDO que a plataforma CHAMA SEU ZÉ foi criada com o propósito de ser facilitadora tecnológica do encontro entre prestadores de serviço, fornecedores e seus potenciais clientes;
CONSIDERANDO que a plataforma tem como meio e fim o papel de educar e transformar a realidade, principalmente dos agentes informais de prestação de serviços, para o desenvolvimento de uma sociedade mais livre, justa e solidária;
CONSIDERANDO que a empresa CHAMA O SEU ZÉ atua exclusivamente como intermediadora de serviços, facilitando o contato entre prestadores, tomadores de serviços e fornecedores de materiais, ferramentas e equipamentos, sem exercer qualquer controle direto sobre a execução dos serviços contratados, ou mesmo sobre negociações, sendo, portanto, parte intermediadora e não executante;
CONSIDERANDO que o contratante reconhece que não há qualquer vínculo empregatício entre a empresa CHAMA O SEU ZÉ e os prestadores de serviços ou os tomadores de serviços, haja vista a natureza autônoma e independente da relação entre as partes, em conformidade com os princípios da autonomia privada e da livre iniciativa;
CONSIDERANDO que não há configuração de hipossuficiência entre as partes, vez que ambos desenvolvem atividade empresarial, independentemente se sobre a roupagem de pessoa física, jurídica ou ente despersonalizado, portanto, plenamente capazes de gerir suas atividades econômicas, de acordo com o art. 966, parágrafo único, do Código Civil Brasileiro;
CONSIDERANDO que as partes envolvidas reconhecem expressamente que esta relação não configura contrato de consumo, nos termos do Código de Defesa do Consumidor (Lei nº 8.078/1990), por tratar-se de uma negociação entre partes empresariais, sem qualquer relação de subordinação, dependência econômica, responsabilidade subsidiária ou solidária;
CONSIDERANDO que as partes compreendem e aceitam os termos e condições de forma livre e consciente, assumindo todas as obrigações e responsabilidades decorrentes deste contrato sem qualquer presunção de desigualdade jurídica ou econômica entre elas;
Resolve o contratante aderir aos seguintes termos:
DAS PARTES
CONTRATADA: CHAMA O SEU ZÉ - TECNOLOGIA LTDA (“Plataforma” ou “Aplicativo”) pessoa jurídica de direito privado devidamente inscrita no CNPJ/MF sob o n. 56.015.414/0001-49, com sede na Avenida Brigadeiro Faria Lima, n. 1572, sala 1022, CEP n. 01.451-917, na cidade e comarca de São Paulo, Estado de São Paulo, neste ato representada por seus sócios administradores.

CONTRATANTE (“prestador de serviço” ou “cliente”): A pessoa física ou pessoa jurídica de direito privado devidamente qualificada nos moldes do "CADASTRO" realizado quando do primeiro acesso ao aplicativo cujos dados de coleta inicial (IP, RG, CPF, CNPJ, Nome, Email, Dados Geolocacionais) serão armazenados como registro e prova de legitimidade do aceite ao presente TCGP junto à CONTRATADA. 

O cliente declara que compreende e aceita estes Termos e Condições Gerais de Provimento de Licenças, Acessos, Produtos e Serviços ("TCGP"), bem como seus respectivos aditivos, que estabelecem os termos e as condições pelas quais as Empresas da CHAMA O SEU ZÉ irão prover as Licenças, Acessos, Produtos e/ou Serviços ao Cliente. O Usuário declara que leu, está ciente e de acordo que: I- O TCGP pode ser modificado a qualquer tempo, sem necessidade de notificação prévia, sendo que tais modificações tornar-se-ão válidas a partir da data do acesso ao SISTEMA. II- O Cliente declara que as informações prestadas serão verdadeiras, exatas, atuais e completas, e que deverão ser mantidas atualizadas durante sua permanência como usuário da plataforma SISTEMA. III- O Cliente assume toda e qualquer responsabilidade pelo mau uso ou pela utilização do login e senha por terceiros não autorizados, ou pela falta de instalação e atualização de programas Anti Spywares, Anti-vírus e outros que impeçam a violação do sistema que é utilizado para ter acesso ao SISTEMA. 

Ao aceitar eletronicamente o presente TCGP, por meio do clique no botão "Aceito o Termo", o Usuário estará automaticamente aderindo e concordando em se submeter integralmente a seus termos e condições e de qualquer de suas alterações futuras. 

1. GERAL
1.1 - As Partes convencionam, por meio deste Contrato, os termos e condições gerais aplicáveis a todas as Licenças, Acessos, Produtos e/ou Serviços a serem providos pela CHAMA SEU ZÉ ao Cliente.
1.2 - As "Partes" ou a “Parte” devem ser interpretadas conforme o contexto, aí incluindo os fornecedores de produtos. Todo(s) o(s) Formulário(s) de Pedido, Anexos, Ordens de Serviço ou qualquer confirmação do pedido celebrado(s) pelas mesmas entidades, juntamente com o TCGP compreendem coletivamente um Contrato apenas entre essas entidades.

2. VIGÊNCIA
2.1 - O presente Contrato permanecerá em vigor enquanto alguma Licença, Acesso, Produto ou Serviço estiver sendo provido ao Cliente pela CHAMA O SEU ZÉ em conformidade com o pagamento da devida licença via mensalidade por plano fixo ou mediante desconto de percentual por prestação de serviço, sem prejuízo de seu dever de sigilo e confidencialidade das informações que obteve acesso em razão e/ou durante a prestação dos serviços.

3. PAGAMENTO E PREÇOS
3.1 - Pagamento e Preços: Em se tratando de prestador de serviço mensalista, modalidade que será posteriormente desenvolvida e disponibilizada pela plataforma, o Cliente pagará pelas Licenças, no prazo de 30 (trinta) dias, a contar da data de acesso do Sistema mediante a emissão de fatura ou desconto em cartão de crédito e sem compensação ou dedução de nenhum valor, incluindo quaisquer valores devidos acumulados a partir do primeiro dia do mês seguinte à data da disponibilização da licença ou acesso, até o último dia do mês da data final de vigência.
3.2 - Caso a plataforma não receba o pagamento na data de vencimento correspondente que se mostre infundada ou não consiga realizar a cobrança por meio do cartão de crédito, a fatura do Cliente será considerada vencida e a CHAMA O SEU ZÉ terá o direito de, sem qualquer aviso prévio, suspender o atendimento e respectivo acesso sem qualquer notificação prévia.
3.3 - Além disso, no caso de atraso no pagamento de qualquer valor devido à CHAMA O SEU ZÉ, este valor será acrescido de juros de 1% (um por cento) ao mês e correção monetária com base na variação positiva do IGPM/FGV entre a data de vencimento e a data do efetivo pagamento, pro rata die, mais uma multa de 2% (dois por cento). Todos os custos e despesas incorridos pela Plataforma para cobrança judicial ou extrajudicial de pagamentos vencidos (incluindo, entre outros, honorários advocatícios, honorários de peritos, custas judiciais).
3.4 - O presente Contrato constitui título executivo extrajudicial, podendo o Cliente ser instado a cumprir qualquer obrigação de pagamento a qualquer tempo, para fins e efeitos do artigo 784, §2, do Código de Processo Civil, independente da assinatura de duas testemunhas.
3.5. Pagamento de Tributos: Salvo disposição expressa em contrário nestes Termos e Condições de Uso ou em instrumentos futuros que venham a ser atualizados, os preços indicados não incluem quaisquer tributos incidentes ou devidos sobre a Licença, o Acesso, os Produtos ou Serviços fornecidos pela plataforma, conforme disposto neste Contrato. Tais tributos serão acrescidos aos valores definidos para a licença de uso. Em caso de aumento de alíquotas, criação de novos tributos, elevação de custos ou qualquer outro evento que gere desequilíbrio econômico-financeiro do contrato, incluindo, mas não se limitando, ao aumento de salários dos empregados, a CHAMA O SEU ZÉ terá o direito de ajustar seus preços proporcionalmente. O Cliente será o responsável por todos e quaisquer tributos retidos ou devidos sobre a Licença e o Acesso ao Sistema disponibilizado pela CHAMA O SEU ZÉ
3.6 - Correção dos Preços: Poderá a CHAMA O SEU ZÉ aplicar reajustes do preço automaticamente no mês de janeiro de cada ano ou a partir da data em que for completado o primeiro ano dessa vigência, conforme o índice positivo acumulado IGPM/FGV + 2% (dois por cento) sobre o valor do preço aplicável, o caso de extinção deste índice bem como em cenário de apuração negativa do referido índice será considerado a aplicação do reajuste de 2% sobre o valor, ou outro índice que legalmente vier a substituí-lo. O aumento percentual será aplicável sobre o preço atualizado do ano anterior.
3.7 - Mudanças Societárias: Caso o cliente, sendo pessoa jurídica, adquira, ou seja adquirido por outra empresa, venha a passar por um processo de fusão, cisão, ou ainda transformada ou incorporada, de modo que a outra empresa, como resultado da transação, é ou seria autorizada a utilizar ou receber os Acessos ou Licenças nos termos deste Contrato, a CHAMA O SEU ZÉ tem o direito de rever os valores, conforme seja alterado o escopo do contrato e/ou respectiva alteração da utilização dos serviços bem como requerer atualização de dados cadastrais e financeiros, devendo o cliente informar imediatamente o início e a conclusão de tais operações.

4.PERMISSÕES E RESTRIÇÕES DE USO

4.1 - Uso: A CHAMA O SEU ZÉ permite que o Cliente utilize os Serviços conforme o objeto estabelecido neste contrato. Os materiais e instalações de comunicações ou redes em conexão com os Serviços, só podem ser utilizados para acessar os Serviços e beneficiar-se dos direitos concedidos nos termos do Contrato. Cabe exclusivamente ao Cliente observar, providenciar e assegurar, constantemente, a manutenção das condições mínimas exigidas no tocante à infraestrutura, equipamentos, sistemas operacionais e ambiente operacional para a adequada instalação, uso e manutenção das Licenças ora outorgada(s).

4.2 - Restrições de Uso: (a) O Cliente não irá: (i) copiar ou modificar qualquer parte dos Serviços; (ii) utilizar ou fornecer os Serviços de forma abrangente, ou de outra forma, para o benefício de terceiros não cadastrados na plataforma (exceto os terceiros, que estejam expressamente autorizados, nos termos do Contrato a ter acesso aos Serviços e promover produtos em marketplace ou universidade interna conveniada); (iii) utilizar quaisquer ambientes, materiais, instalações de telecomunicações ou redes fornecidas por ou em nome da CHAMA O SEU ZÉ, exceto para acessar e utilizar corretamente os Serviços; ou (iv) fundir, decompilar, desmontar, ou fazer engenharia reversa do Software (exceto se expressamente permitido por lei ou disposições legais, para assegurar a interoperabilidade com outras tecnologias onde esses direitos não podem ser modificados mediante acordo, e desde que a CHAMA O SEU ZÉ tenha sido comunicada previamente a respeito), ou alterar o nome do arquivo do Software. 
4.3 - As Licenças, Acessos, Produtos e/ou Serviços contratados pelo Cliente deverão ter exclusiva aplicação interna, para o benefício do próprio Cliente, e não poderão ser utilizados sob qualquer circunstância para exploração comercial a não ser para a prestação de serviços a terceiros cadastrados na plataforma.
Sendo identificado o uso de itens adicionais aos contratados ou uso irregular dos itens contratados, a CHAMA SEU ZÉ terá direito de cobrar tais itens adicionais, sem prejuízo do ressarcimento das perdas e danos cabíveis em razão da violação e do direito da plataforma rescindir a presente licença.
4.3.1 - Quaisquer Informações, Materiais ou outros direitos fornecidos, dispostos neste Contrato, não são transferíveis e não são sublicenciáveis pelo Cliente, exceto aqueles direcionados a promoções por fornecedores cadastrados na própria plataforma, cuja destinação promocional será previamente autorizada e informada ao Cliente sobre condições para seu exercício.
4.3.2 - Avaliação e Testes: Todas as avaliações prévias ou testes dos Serviços, Produtos, Acessos ou Licenças estão sujeitos aos termos do Contrato, a menos que de outra forma notificado pela plataforma.
4.3.3 - Não é objeto deste instrumento particular qualquer tipo de alteração, codificação, modificação, otimização, customização, implementação e afins no SOFTWARE, ficando a contratação vinculada estritamente às funcionalidades do mesmo ofertado ao CLIENTE na data de assinatura do presente contrato. Anui ainda o CLIENTE que no caso de alteração, codificação, modificação, otimização, customização, implementação diversa do que vinculado em oferta direta ao público e previstas nas tratativas de contratação deverá a mesma utilizar de aquisição de pacotes adicionais dos referidos serviços mediante solicitação prévia, disponibilidade e condição de aprovação de desenvolvimento pela CHAMA O SEU ZÉ, não constituindo a recusa desta qualquer infração contratual ou mesmo causa vinculativa ao presente instrumento particular, aplicando-se sobre as mesmas todas as regras de condições de uso. 

5. OBRIGAÇÕES DO PRESTADOR DE SERVIÇOS NÃO ASSINANTE

5.1 - Permitir a retenção de 30% dos valores referentes a taxa de visita aos Clientes Finais no aplicativo Chama o Seu Zé como forma de pagamento;
5.2 - Permitir a retenção de 30% de multa pela desistência ou cancelamento, caso o Cliente Final a solicite através do aplicativo Chama o Seu Zé, e haja fundadas suspeitas sobre infrações praticadas, tal como a adoção de valores excessivos ou violação as normas de conduta da plataforma; 
5.3 – Efetuar todas as transações financeiras de recebimento dos serviços via plataforma para correta retenção do percentual, sob pena de multa proporcional de 30% sobre o valor devido. Sendo reincidente contumaz, a plataforma poderá realizar a exclusão do prestador de serviço em definitivo de seus cadastros;
5.4 - Agir de maneira íntegra, honesta e transparente, respeitando os padrões estabelecidos pela Chama o Seu Zé, conforme o manual disponível;
5.5 – Indenizar todo e qualquer dano causado ao Cliente Final em decorrência da falha na prestação do serviço ou em razão dele, de ordem penal, civil ou administrativa. Sendo a plataforma demandada judicialmente, terá direito de regresso contra o causador do dano potencial ou em concreto.
5.4 - O Prestador de serviço terá acesso a Universidade Corporativa ou qualquer outro benefício listado para assinantes apenas na modalidade dos serviços básicos, assim definidos pela própria instituição, restringindo-se unicamente a autorização à plataforma para acesso do Prestador.

6. RESTRIÇÕES DE TERCEIROS

6.1 - Restrições de Terceiros: Os fornecedores terceiros, que disponibilizam produtos ou serviços em áreas próprias dentro da plataforma, podem impor restrições adicionais relacionadas ao uso de suas informações, produtos ou serviços. Tais restrições poderão ser modificadas ao longo do tempo, desde que os fornecedores estejam devidamente cadastrados e aprovados pela plataforma.
6.2. Atualização dos Termos de Terceiros: A CHAMA O SEU ZÉ envidará esforços comercialmente razoáveis para garantir que os Termos de Uso aplicáveis aos serviços ou produtos fornecidos por terceiros estejam sempre atualizados, conforme as políticas vigentes de cada fornecedor ou prestador de serviço. As restrições impostas por terceiros serão vinculantes ao Cliente, com a mesma força das disposições deste Contrato.
6.3. Suspensão e Cancelamento por Instrução de Terceiros: Os fornecedores terceiros cadastrados na plataforma podem, mediante decisão justificada, exigir que a CHAMA O SEU ZÉ restrinja, suspenda ou cancele o acesso do Cliente a suas informações, materiais ou serviços, sempre que houver violação de suas políticas. Tais decisões deverão ser comunicadas de imediato à plataforma, garantido o contraditório,
6.4. Ações para Garantia de Conformidade: Se a CHAMA O SEU ZÉ adotar qualquer medida para assegurar a integridade e operabilidade de seus processos ou políticas, a plataforma:
a) Envidará esforços razoáveis para notificar o Cliente antecipadamente e buscar uma imediata correção da conduta, sendo o responsável pela conduta lesiva, em potencial ou concreta, o ônus pela reparação.
b) Não poderá ser responsabilizada por quaisquer danos ou prejuízos que o Cliente venha a sofrer em decorrência de tais medidas.
c) O fornecedor terceiro poderá solicitar que a CHAMA O SEU ZÉ forneça detalhes sobre o uso, pelo Cliente, de suas informações, materiais ou serviços, a fim de apurar qualquer suspeita de infração ao presente Contrato.
d) O Cliente concorda que os parceiros comerciais da CHAMA O SEU ZÉ, incluindo empresas terceirizadas contratadas pelo Cliente para a prestação de serviços de consultoria ou outros, são independentes da plataforma e não atuam como seus agentes. A CHAMA O SEU ZÉ não será responsável por atos praticados por esses parceiros, salvo se o parceiro estiver atuando como subcontratado da plataforma, mediante contrato formal, conforme os termos deste documento.

7. DIREITOS DE PROPRIEDADE INTELECTUAL

7.1 - A CHAMA O SEU ZÉ detém toda a propriedade e direitos sobre a propriedade intelectual contida nas Licenças, Acessos, Produtos e Serviços, inclusive nas melhorias e desenvolvimentos, a qualquer tempo implementadas, assim como sobre os Materiais fornecidos a clientes ou usuários. 
7.2 - As Licenças, Acessos, Produtos e Serviços e os Materiais estão sujeitos à proteção em conformidade com as leis de direitos autorais, segredos comerciais e propriedade intelectual aplicáveis. Ademais, os direitos concedidos sob este Contrato são os únicos direitos que a CHAMA O SEU ZÉ concede com relação a Licenças, Acessos, Produtos e Serviços e Materiais da plataforma e não há qualquer licença implícita nas Licenças, Acessos, Produtos, Serviços ou Materiais, ou em qualquer outra informação, documentação, registro, produto ou bem (ou qualquer parte, parcela ou aspecto) de propriedade da CHAMA O SEU ZÉ. 
7.3 - A CHAMA O SEU ZÉ ou seus fornecedores detêm todos os direitos sobre as Licenças, Acessos, Produtos, Serviços e Materiais da plataforma e possuem pleno poder e autoridade para conceder os direitos estipulados neste Contrato, e a plataforma detém todos os direitos não especificamente outorgados ao Cliente neste Contrato. 
7.4 - Restrição do Uso dos Conteúdos da CHAMA O SEU ZÉ: O Cliente não usará nenhum Conteúdo que tiver acesso durante ou em razão da prestação dos serviços, e não o distribuirá a terceiros de maneira contrária ou violará à legislação, regulamentos ou normas na esfera federal, estadual ou municipal, ou no âmbito internacional. 
7.5 - A CHAMA O SEU ZÉ detém o direito de bloquear o acesso a certos Conteúdos temporária ou permanentemente, a seu exclusivo critério, sendo oportunizado o contraditório de maneira diferida nos casos em que a demora da decisão puder causar prejuízos.
7.6 - O Cliente deverá comunicar à CHAMA O SEU ZÉ sobre qualquer mau uso, vazamento de dados e/ou abuso de Conteúdos de que tiver conhecimento, e reconhece que não poderá transferir, ceder, autorizar ou emprestar o(s) Produto(s) adquirido(s).
7.7 - O Cliente, ao acessar o Produto, Licença ou Serviço, obriga-se a: (i) tomar todas as providências necessárias para evitar que seus prepostos ou terceiros copiem ou reproduzam indevidamente softwares ou conteúdos; (ii) abster-se de utilizar meios eletrônicos para extração dos Conteúdos, como a utilização de ferramentas de software de acesso, busca ou pesquisa automática para cópia de Conteúdos. Essas obrigações não se exaurem com o término da vigência deste Contrato de Permissão.
7.8 - Exceto conforme estabelecido neste Contrato, o Cliente não poderá usar o nome, qualquer derivação do nome ou as marcas comerciais da CHAMA O SEU ZÉ ou das empresas pertencentes ao seu grupo econômico. O Cliente não poderá remover ou ocultar qualquer notificação de propriedade incorporada nas Licenças, Acessos, Produtos, Serviços e Materiais da CHAMA O SEU ZÉ, ou os códigos de segurança ou proteção contra cópia, replicação ou duplicação.

8. SEGURANÇA

8.1 - Quando os serviços forem prestados ao usuário individual, não é permitido o uso concomitante ou compartilhado dos serviços entre vários usuários. No entanto, o Cliente poderá transferir um serviço de um usuário para outro no mesmo país, mediante notificação e aceitação da CHAMA O SEU ZÉ. 
8.2 - O acesso aos Serviços pode estar sujeito ao uso de senhas, cartões com chip ou outros dispositivos de segurança ("Credenciais de Segurança") fornecidos pela CHAMA O SEU ZÉ. Essas Credenciais de Segurança não devem ser compartilhadas e a CHAMA O SEU ZÉ poderá alterar as Credenciais de Segurança, mediante notificação ao Cliente ou usuário por razões de segurança. 
8.3 – A CHAMA O SEU ZÉ envidará esforços razoáveis para: (a) examinar e avaliar os Serviços e seus sistemas relacionados quanto a qualquer código ou dispositivo destinado a prejudicar o funcionamento de qualquer computador ou banco de dados, ou impedir o acesso ou operação de qualquer programa ou dados, utilizando software de detecção amplamente aceito no setor; (b) proteger seus ambientes de sistema de acordo com os padrões amplamente aceitos no setor, garantindo que os serviços não possam ser acessados por pessoas não autorizadas ou software malicioso; (c) resolver qualquer violação de segurança de que tenha conhecimento; (d) deverá o prestador de serviço zelar pela segurança, informando a plataforma a despeito de quaisquer violações a que tiver conhecimento.
8.2 - A CHAMA O SEU ZÉ envidará esforços para fornecer um ambiente seguro para transmissão e recebimento de informações. O sumário com todos os procedimentos e políticas da CHAMA O SEU ZÉ relativos à segurança de dados ficará disponível mediante solicitação do Cliente. A CHAMA O SEU ZÉ se isenta de responsabilidade por quaisquer danos ou prejuízos nos seguintes casos: (i) falhas nos serviços prestados por terceiros (por exemplo, empresas de telecomunicações); (ii) divulgação ou publicação de informações durante a transmissão, desde a remessa até o destino, causada por sistemas ou componentes que não façam parte das redes da CHAMA O SEU ZÉ, incluindo hardware, plataformas, sistemas de rede, sistemas de telecomunicações, acesso à internet, linhas telefônicas e equipamentos de comunicação do Cliente.
8.3 - Se solicitado pelo Cliente, este terá o direito de analisar os resultados de avaliação de segurança, testes de vulnerabilidade e testes de recuperação de desastres realizados por terceiros e que estejam disponíveis nas aplicações hospedadas contendo informações do Cliente. Após essa verificação, a CHAMA O SEU ZÉ fornecerá ao Cliente informações adicionais, conforme solicitado por escrito, sobre a segurança de dados da CHAMA O SEU ZÉ que não constem nos relatórios. 
4.4 - O Cliente poderá realizar verificações anuais presenciais, desde que acordados preço, prazo, metodologia, medidas, políticas e procedimentos de segurança da CHAMA O SEU ZÉ. Essas verificações devem ser agendadas e realizadas durante o horário comercial, observando-se as políticas de segurança e as obrigações de confidencialidade da CHAMA O SEU ZÉ.
8.5 - A CHAMA O SEU ZÉ deverá, assim que possível, notificar o Cliente sobre quaisquer violações reais ou tentativas de violação na segurança, acessos não autorizados ou suspeitos ao Aplicativo Hospedado, indicando que uma pessoa pode ter danificado ou tentado danificar os Aplicativos Hospedados, ou obtido acesso não autorizado, de modo que afete adversamente as informações do Cliente, ou cause corrupção, perda ou erro na transmissão ou armazenamento de dados. Em caso de qualquer violação de segurança, a CHAMA O SEU ZÉ deverá realizar uma análise para identificar a causa da referida violação e fornecer ao Cliente informações quando solicitadas.
8.6 - Tendo em vista a segurança e o sigilo das informações, o Cliente não poderá ter acesso ao servidor da CHAMA O SEU ZÉ, inclusive durante a vigência do Contrato, uma vez que este é compartilhado por outros Clientes.
8.7 - A CHAMA O SEU ZÉ não se responsabiliza pela inoperância do sistema em virtude de restrições impostas pelo provedor de internet do Cliente, que impeçam o acesso aos sites da CHAMA O SEU ZÉ, os quais devem ser acessados com o uso dos protocolos necessários para o perfeito funcionamento do software (incluindo FTP, HTTP, POP, SMTP e outros), ou que impeçam o acesso aos servidores da CHAMA O SEU ZÉ devido a configurações de PROXY e FIREWALL.
8.8 - O Cliente deverá comunicar à CHAMA O SEU ZÉ sobre qualquer mau uso e/ou abuso de conteúdos que vier a ter conhecimento.
8.9 - O Cliente não poderá transferir, ceder, autorizar ou emprestar o(s) Produto(s) adquirido(s), salvo mediante expressa autorização da plataforma.
8.10 - Proteção de Acesso: o gerenciamento de login e senha será de exclusiva responsabilidade do Cliente.
8.11 - O Cliente é responsável por todo o acesso aos Conteúdos da CHAMA O SEU ZÉ realizado por seus funcionários, prepostos e/ou representantes, ou por meio de suas senhas de acesso, mesmo que referido uso não tenha sido previamente autorizado. É vedado o compartilhamento das senhas de acesso aos Produtos da CHAMA O SEU ZÉ emitidas para uso exclusivo do Cliente.
8.12 - O Cliente se compromete a: (i) comunicar à CHAMA O SEU ZÉ sobre qualquer uso não autorizado de login e/ou senha de que tenha conhecimento; (ii) não realizar cópias, backups ou reproduções dos conteúdos da CHAMA O SEU ZÉ; (iii) efetuar o procedimento de finalização da utilização on-line (logoff) ao final do uso do(s) Produto(s) on-line, podendo a CHAMA O SEU ZÉ realizar o logoff automático após permanência inativa por tempo definido a critério exclusivo da CHAMA O SEU ZÉ; (iv) não permitir que o(s) Produto(s) on-line sejam utilizados por terceiros, sendo vedada a cessão, sublicença, autorização, fornecimento ou disponibilização do seu login, senha ou permissões de acesso.
8.13 - Condições de Acesso: O acesso do Cliente ao(s) Produto(s) está condicionado a: (a) acesso ao(s) Produto(s) por meio de endereço eletrônico (URL) definido pela CHAMA O SEU ZÉ e/ou para acesso local em caso de produtos Desktop; (b) respeito contínuo do Cliente e de seus usuários aos termos deste Contrato, em especial aos direitos autorais e de propriedade intelectual do(s) Conteúdo(s); (c) utilização de conexão à Internet por provedor contratado pelo Cliente, com velocidade e banda compatíveis com o volume de conteúdo acessado; (d) utilização de software de navegação (browser) de Internet pelo próprio Cliente.
8.14 - A CHAMA O SEU ZÉ não será responsável por falhas no desempenho do Software nas seguintes hipóteses: (a) mau funcionamento de software não fornecido pela CHAMA O SEU ZÉ; (b) mau funcionamento de hardware; (c) negligência ou falha do Cliente; (d) falha do Cliente em seguir as instruções estabelecidas na Documentação; (e) conexão sem fio inadequada. Se a CHAMA O SEU ZÉ descobrir que a falha foi causada por uma dessas razões, reserva-se o direito de cobrar do Cliente pela investigação da falha.

9. SUPORTE

9.1 - Suporte Fornecido: Com a finalidade de auxiliar na resolução de problemas técnicos relativos aos Serviços, a CHAMA O SEU ZÉ poderá fornecer atendimento telefônico e/ou acesso online ao seu helpdesk, ou poderá disponibilizar ferramentas de autoatendimento (tickets), sempre de forma remota. Os serviços de suporte contínuo às Licenças e Produtos serão fornecidos em contrapartida ao pagamento pontual do preço aplicável à licença de uso, abrangendo:
(1) soluções de problemas sempre que tais soluções se tornarem conhecidas e publicadas pela CHAMA O SEU ZÉ;
(2) correções de problemas relevantes que a CHAMA O SEU ZÉ seja capaz de diagnosticar e corrigir;
(3) melhorias que a CHAMA O SEU ZÉ decida incorporar nos produtos, licenças e/ou serviços, sem cobrança adicional;
(4) suporte remoto para o uso e manutenção das Licenças, Produtos e Serviços concedidos.
Os serviços de suporte prestados pela CHAMA O SEU ZÉ não incluirá atualizações ou suporte em:
(1) Licenças instaladas em equipamentos não autorizados pela CHAMA O SEU ZÉ;
(2) softwares, equipamentos ou acessos não licenciados ou fornecidos pela CHAMA O SEU ZÉ;
(3) Licenças e acessos utilizados de forma inadequada ou em desacordo com as Especificações.
9.2 - Suporte Remoto: A CHAMA O SEU ZÉ poderá solicitar o consentimento do Cliente para instalar agentes de software em seus sistemas, com a finalidade de prestar suporte remoto. Caso o Cliente negue tal consentimento e a CHAMA O SEU ZÉ forneça suporte por outros meios, a CHAMA O SEU ZÉ se reserva o direito de cobrar valores adicionais.
9.3 - Atendimento ao Cliente: O Suporte Técnico será prestado via e-mail ou telefone. O horário de atendimento será das 8h00 às 12h00 e das 13h00 às 18h00, de segunda a sexta-feira (Horário de Brasília), exceto feriados nacionais, estaduais ou municipais.
9.4 - Exclusões do Suporte Técnico:
O Suporte Técnico não inclui:
(a) Alterações devido à mudança de localidade do Cliente;
(b) Mudanças de equipamento ou sistema operacional, incluindo novas versões ou releases;
(c) Falhas decorrentes de mau funcionamento do computador utilizado para acessar o programa;
(d) Divulgação de informações relacionadas ao código-fonte ou modelo de banco de dados;
(e) Alterações na estrutura do banco de dados;
(f) Divulgação de qualquer outra informação considerada propriedade intelectual da CHAMA O SEU ZÉ;
(g) Treinamentos sobre novas funcionalidades ou para novos usuários via telefone ou e-mail.
9.5. Prazos de Atendimento: A CHAMA O SEU ZÉ terá o prazo de 48 (quarenta e oito) horas para finalização de qualquer chamado iniciado pelo Cliente junto ao suporte técnico.

10. RESCISÃO E SEUS EFEITOS
10.1 - Rescisão por Iniciativa do Prestador de Serviço: O Prestador de Serviço, não assinante da plataforma, poderá rescindir o contrato de forma unilateral a qualquer momento, mediante opção selecionada dentro do aplicativo. A rescisão resultará na interrupção imediata do acesso à plataforma e na remoção do prestador da lista de fornecedores disponíveis no aplicativo. Não haverá qualquer obrigação de pagamento por serviços futuros, exceto aqueles já realizados ou pendentes de aprovação até a data de rescisão.
10.2 - Rescisão por Iniciativa da CHAMA O SEU ZÉ: A CHAMA O SEU ZÉ poderá rescindir o contrato de prestação de serviços a qualquer tempo, com efeito imediato e sem aviso prévio, nas seguintes hipóteses: a) Descumprimento de qualquer cláusula ou obrigação deste contrato pelo Prestador de Serviço; b) Conduta do Prestador que prejudique a imagem, reputação ou funcionamento da CHAMA O SEU ZÉ; c) Recebimento de reclamações recorrentes ou fundadas de clientes em relação à má prestação de serviços pelo Prestador; d) Violação das políticas de qualidade, termos de uso ou normas operacionais da plataforma; e) Uso indevido de dados dos clientes ou violação de privacidade, em desacordo com a legislação vigente.
10.3 - Rescisão por Interesse de Ambas as Partes: O contrato poderá ser rescindido por acordo mútuo entre o Prestador de Serviço e a CHAMA O SEU ZÉ, mediante formalização por escrito, incluindo a resolução de quaisquer pendências financeiras ou de serviços em andamento.
10.4 - Efeitos da Rescisão: Com a rescisão do contrato, os seguintes efeitos serão aplicados: a) O Prestador de Serviço terá seu acesso ao aplicativo e à plataforma CHAMA O SEU ZÉ imediatamente revogado, incluindo a suspensão de qualquer interação ou oferta de serviços via plataforma; b) O Prestador será responsável por concluir quaisquer serviços já iniciados, garantindo o cumprimento das obrigações pendentes até a data de rescisão; c) A CHAMA O SEU ZÉ poderá reter quaisquer pagamentos devidos ao Prestador até que todas as obrigações pendentes sejam cumpridas e eventuais débitos quitados; d) O Prestador deverá cessar imediatamente o uso de qualquer material, marca, nome ou qualquer outra referência à CHAMA O SEU ZÉ em suas comunicações e promoções; e) As obrigações de confidencialidade, bem como a proteção de dados dos clientes, permanecerão em vigor por prazo indeterminado, mesmo após o término do contrato, conforme legislação aplicável.
10.5 - Reativação: Em caso de rescisão por violação contratual ou má conduta, o Prestador de Serviço poderá solicitar sua reativação na plataforma mediante pedido formal à CHAMA O SEU ZÉ. A reativação estará condicionada à regularização de quaisquer pendências, à análise criteriosa de sua conduta e ao cumprimento das condições estabelecidas pela plataforma. A aprovação da reativação ficará a critério exclusivo da CHAMA O SEU ZÉ, sem garantia de readmissão.


11.1. FATORES EXTERNOS:
A CHAMA O SEU ZÉ poderá, mediante notificação rescindir o Serviço, no todo ou em parte, modificá-lo ou alterar os termos de fornecimento, nos seguintes casos:
(a) Se o Serviço depender de contrato entre a CHAMA O SEU ZÉ e terceiros, e tal contrato for alterado ou cancelado;
(b) Se o fornecimento do Serviço se tornar ilegal ou estiver em desacordo com qualquer legislação, regulamento, norma, decisão ou determinação de autoridade competente;
(c) Se o Serviço for alvo de reivindicação ou alegação de violação de direitos de terceiros.
A CHAMA O SEU ZÉ notificará o Cliente com antecedência, sempre que possível, sobre qualquer rescisão ou modificação, salvo quando o evento gerador de caso fortuito ou força maior estiver fora de seu controle. A data indicada na Notificação será considerada a “Data de Mudança” e caso a rescisão ou modificação prejudique substancialmente a natureza ou os direitos do Serviço, o Cliente poderá rescindir o Serviço afetado, mediante comunicação prévia à CHAMA O SEU ZÉ com antecedência mínima de 7 (sete) dias.

11.2 - Descontinuidade de Acesso, Produto ou Serviço: Caso a CHAMA O SEU ZÉ descontinue determinado Acesso, Produto ou Serviço objeto deste contrato, enviará ao Cliente um aviso com pelo menos 60 (sessenta) dias de antecedência. Na data estipulada para descontinuação, o acesso ao Produto ou Serviço será interrompido. No caso de descontinuação por obsolescência do software, o Contrato será rescindido, e o Cliente não terá mais acesso ao Produto e/ou Serviço.

11.3 - Isenção de Responsabilidade Após Rescisão: Após o término da vigência do contrato, a CHAMA O SEU ZÉ não se responsabilizará pela continuidade do Produto ou Serviço, nem pela correção, precisão ou veracidade das informações capturadas em bases de dados de terceiros ou do governo, ou por falhas no uso das Licenças, Acessos, Produtos ou Serviços pelo Cliente, nem por falhas de operação ou configuração de equipamentos e sistemas do Cliente.
11.4 - Reembolsos: Nos casos de rescisão pela CHAMA O SEU ZÉ, o Cliente terá direito à devolução dos valores proporcionais aos dias restantes para completar o período da licença mensal.

12. PRIVACIDADE DE DADOS
12.1 - Tratamento de Dados Pessoais: As Partes processarão os Dados Pessoais de que tiverem acesso em razão da prestação de serviço em conformidade com as leis e regulamentos aplicáveis. O Cliente concorda em divulgar à CHAMA O SEU ZÉ quaisquer Dados Pessoais, inclusive aqueles disponibilizados mediante upload para o Serviço, e garante que a divulgação será realizada de acordo com as leis e regulamentos aplicáveis, nos termos do anexo 1, ao final do documento.
12.2 - Cooperação: As Partes envidarão esforços razoáveis para prestar assistência mútua em investigações e processos relacionados a alegações, reclamações, ou litígios relativos ao acesso, uso, transformação ou divulgação não autorizada de Dados Pessoais.
12.3 - Medidas de Segurança: Cada Parte se compromete a manter medidas físicas, técnicas e organizacionais adequadas para proteger os Dados Pessoais do Cliente contra destruição, perda, alteração, divulgação, acesso acidental, não autorizado ou ilegal.
12.4. Conteúdos Ilegais ou Impróprios: O Cliente concorda em não inserir ou armazenar conteúdos ilegais ou impróprios nos centros de processamento da CHAMA O SEU ZÉ. Caso deseje utilizar serviços que envolvam conteúdos sensíveis, o Cliente e a CHAMA O SEU ZÉ deverão acordar previamente, por escrito, as medidas de segurança adicionais. 
12.5 - O Cliente será responsável por obter todas as permissões necessárias para a inclusão do conteúdo nos serviços de hospedagem e concederá à CHAMA O SEU ZÉ permissão para usar, armazenar e processar o conteúdo conforme necessário para a prestação dos serviços.
12.6 - Restauração de Conteúdos: Se o conteúdo do Cliente for perdido ou danificado, a CHAMA O SEU ZÉ não terá responsabilidade de auxiliar na restauração desse conteúdo para o serviço contratado;
12.7 - Adequação à LGPD: O Cliente reconhece que este Termo de Condições Gerais de Prestação (TCGP) poderá ser alterado em razão da Lei nº 13.709/2018 (LGPD), e será solicitado que o Cliente refaça a aceitação dos termos, conforme anexo 1 abaixo.
12.8 - Armazenamento em Nuvem: O Cliente reconhece que seus dados não são armazenados em servidores próprios da CHAMA O SEU ZÉ, mas sim em datacenters em nuvem, contratados de empresas devidamente credenciadas e vinculados por contratos específicos de armazenamento.

13. ISENÇÃO DE RESPONSABILIDADE
A CHAMA O SEU ZÉ, suas afiliadas e/ou terceiros que concedam o acesso aos dados, à plataforma ou ao software não poderão ser responsabilizados por:
a) Quaisquer reivindicações relacionadas à incapacidade ou falha do Cliente em realizar pesquisas ou decisões tomadas com base nos dados, ou ainda pela aquisição, compilação, interpretação, edição, redação, relatório ou entrega de dados;
b) Quaisquer reivindicações decorrentes do uso de produtos e/ou acessos sem a prévia aprovação por escrito da CHAMA O SEU ZÉ, em conjunto com quaisquer dados, resultados de serviços ou software que não tenham sido fornecidos pela CHAMA O SEU ZÉ;
c) Quaisquer reivindicações derivadas de modificações dos produtos e acessos realizadas por qualquer pessoa não autorizada pela CHAMA O SEU ZÉ.

14. RESPONSABILIDADE POR DANOS
14.1 - Natureza de Intermediação: A CHAMA O SEU ZÉ atua exclusivamente como intermediadora entre o Prestador de Serviço e o Cliente, facilitando a conexão entre as partes por meio da plataforma digital, sem qualquer participação, ingerência, controle ou supervisão direta sobre os serviços efetivamente prestados pelos Prestadores de Serviço cadastrados. Desta forma, a CHAMA O SEU ZÉ não se responsabiliza, em nenhuma hipótese, pela execução, qualidade, resultado ou quaisquer outros aspectos relacionados aos serviços oferecidos ou prestados pelos Prestadores aos Clientes.
14.2 - Isenção de Responsabilidade Cível: A CHAMA O SEU ZÉ não poderá ser responsabilizada por quaisquer danos diretos ou indiretos, materiais ou morais, que venham a ser causados ao Cliente ou a terceiros em razão dos serviços executados pelos Prestadores de Serviço. Qualquer disputa, reclamação, indenização ou demanda judicial que envolva a prestação de serviços, incluindo, mas não se limitando a, falhas, atrasos, vícios, defeitos, ou inadimplemento contratual, deverá ser tratada diretamente entre o Cliente e o Prestador de Serviço. A plataforma não possui responsabilidade solidária ou subsidiária por eventuais ações cíveis decorrentes de tais fatos.
14.3 - Isenção de Responsabilidade Criminal - A CHAMA O SEU ZÉ não poderá ser responsabilizada criminalmente por atos ilícitos, dolosos ou culposos, praticados pelos Prestadores de Serviço ou Clientes, incluindo, mas não se limitando a, fraudes, estelionato, apropriação indébita, ou qualquer outro crime que venha a ser cometido no âmbito da prestação dos serviços ou em decorrência da relação estabelecida entre Prestador de Serviço e Cliente. Qualquer responsabilização criminal será de inteira responsabilidade da parte que cometer o ato ilícito, sem qualquer imputação à CHAMA O SEU ZÉ.
14.4 - Isenção de Responsabilidade Administrativa: A CHAMA O SEU ZÉ não será responsável por qualquer infração de natureza administrativa cometida pelos Prestadores de Serviço ou Clientes, tais como descumprimento de normas regulatórias, fiscais, trabalhistas, ambientais ou de qualquer outra legislação aplicável. Cabe exclusivamente ao Prestador de Serviço e ao Cliente observarem as obrigações e responsabilidades legais decorrentes da relação de prestação de serviços, incluindo, mas não se limitando a, licenças, alvarás, registros e tributos aplicáveis à execução dos serviços contratados.
14.5 - Autonomia dos Prestadores de Serviço: Os Prestadores de Serviço cadastrados na plataforma CHAMA O SEU ZÉ são profissionais independentes, sem qualquer vínculo empregatício, societário ou de subordinação com a plataforma. Assim, a CHAMA O SEU ZÉ não se responsabiliza por atos, omissões ou condutas dos Prestadores de Serviço no exercício de suas atividades profissionais, sendo estes os únicos responsáveis pela adequação, legalidade e segurança dos serviços prestados.
14.6 - Limitação de Responsabilidade: Em nenhuma hipótese, a CHAMA O SEU ZÉ será responsável por quaisquer perdas e danos, lucros cessantes, perda de oportunidade, interrupção de negócios ou qualquer outro tipo de prejuízo que venha a ser alegado por Clientes, Prestadores de Serviço ou terceiros, em razão da utilização da plataforma ou da prestação dos serviços intermediados. A responsabilidade da CHAMA O SEU ZÉ limitar-se-á, exclusivamente, à correta disponibilização da ferramenta de intermediação digital, nos termos e condições estabelecidos no presente contrato.
14.7 - Disputas Entre as Partes: Quaisquer conflitos, desentendimentos ou litígios surgidos entre Prestadores de Serviço e Clientes deverão ser resolvidos entre as partes envolvidas, sem qualquer responsabilidade da CHAMA O SEU ZÉ pela mediação, solução ou indenização decorrente de tais disputas.

15. ATUALIZAÇÕES DE VERSÃO
As atualizações referentes a correções e/ou aprimoramento da versão serão realizadas de forma automática pelo Cliente, enquanto vigente o presente instrumento e desde que o Cliente esteja adimplente. Não estão cobertas por este instrumento as alterações ou implementações de funcionalidades no SOFTWARE solicitadas pelo Cliente. Caso essas solicitações sejam atendidas pela CHAMA O SEU ZÉ, serão objeto de contrato separado e cobradas de forma adicional. O Cliente fica ciente e autoriza, desde já, que todas as atualizações realizadas no SOFTWARE serão incluídas no produto padrão e poderão estar disponíveis para todos os clientes da CHAMA O SEU ZÉ. O Cliente pode sugerir funcionalidades desejáveis, mas a responsabilidade pela definição do conteúdo, alterações e prazos das novas versões será exclusiva da CHAMA O SEU ZÉ.

A assinatura do presente instrumento ocorre de forma on-line e digital, de modo que o Cliente concede poderes ao usuário que fizer o primeiro acesso ao sistema para validar os termos aqui constantes, garantindo que referido usuário tenha poderes administrativos ou previstos em seu contrato social para tanto.

A assinatura, endereço IP, horário e condições de validação, registrados eletronicamente no momento da aceitação deste instrumento por meio de clique em "li e aceito", serão automaticamente armazenados no banco de dados da CHAMA O SEU ZÉ e terão plena validade jurídica como meio de prova para validar a contratação aqui formalizada, em conjunto com e-mails e tratativas contratuais realizadas verbal, física ou digitalmente.

16. FORO - Fica eleito o foro da comarca de São Paulo, Estado de São Paulo, como o único competente para dirimir quaisquer dúvidas ou controvérsias decorrentes deste instrumento particular, com exclusão de qualquer outro, por mais privilegiado que seja.

São José do Rio Preto, SP, Data mediante Metadados.

ASSINATURA DIGITAL




ANEXO 1

TERMOS GERAIS DE POLÍTICA DE DADOS

1. INTRODUÇÃO
Ao utilizar o aplicativo CHAMA O SEU ZÉ, você nos confia seus dados pessoais. Nosso compromisso é garantir a proteção dessas informações e este termo descreve os dados pessoais que coletamos, como eles são usados e compartilhados, além de suas opções em modificar, cancelar o compartilhamento ou fazer apontamentos.

2. VISÃO GERAL
A. Escopo
1.	Este termo se aplica a todos os usuários do aplicativo CHAMA O SEU ZÉ e seus serviços, incluindo o Marketplace, a Universidade Corporativa e quaisquer outras funcionalidades disponibilizadas.
2.	Ele abrange tanto os Clientes Finais, que utilizam o aplicativo para solicitar serviços ou adquirir produtos, quanto os Prestadores de Serviços, assinantes ou não, que oferecem serviços de maneira individual por meio da plataforma.
3.	As práticas de privacidade do CHAMA O SEU ZÉ seguem as legislações aplicáveis do Brasil, incluindo, mas não se limitando, a Lei Geral de Proteção de Dados Pessoais (LGPD).

B. Definições
1.	Dados Pessoais: Qualquer informação relacionada a uma pessoa natural identificada ou identificável que tenha acesso a plataforma, independente do objetivo;
2.	Dados Sensíveis: Dados pessoais que revelem origem racial ou étnica, convicção religiosa, opinião política, entre outros previstos na LGPD.
3.	Tratamento de Dados: Toda operação realizada com dados pessoais, como coleta, armazenamento, uso, compartilhamento, entre outros.
4.	Controlador e Operador: O controlador é quem toma decisões sobre o tratamento de dados, enquanto o operador apenas executa as ordens do controlador;

C. Princípios Da Proteção De Dados
1.	Finalidade: Tratamento de dados será realizado para propósitos específicos e legítimos, destinados à operação da plataforma e garantir a conformidade dos serviços, informados de maneira clara ao usuário. Outrossim, serão compartilhados com stakeholders e demais parceiros de negócios cadastrados na plataforma mesmo após a opção do usuário de excluir sua conta, pelo período de 48 (quarenta e oito meses) com a finalidade de pesquisa de mercado.
2.	Necessidade: A coleta de dados será limitada ao mínimo necessário para a realização dos serviços, sendo que qualquer exigência adicional será previamente informada aos usuários, prestador de serviço ou não, para as finalidades nela especificadas.
3.	Transparência: O tratamento dos dados será feito de forma transparente, com todas as informações sobre como os dados são utilizados sendo disponibilizadas aos usuários.
4.	Segurança e Prevenção: Medidas de segurança apropriadas serão adotadas para proteger os dados de acessos não autorizados e de situações acidentais ou ilícitas, sendo que qualquer espécie de vazamento ou incidente serão comunicados imediatamente as pessoas afetadas.

D. Consentimento Dos Usuários
1.	Coleta e Consentimento: O tratamento de dados pessoais pelo CHAMA O SEU ZÉ dependerá de consentimento livre, informado e inequívoco dos usuários, conforme a assinatura dos termos ao acessar a plataforma, quando aplicável para situações específicas assim definidas em lei e nas hipóteses definidas nesta política.
2.	Revogação do Consentimento: O usuário pode revogar o consentimento a qualquer momento, sendo assegurada a exclusão de seus dados conforme suas preferências, exceto nos casos em que a manutenção for exigida por lei. No presente caso, a Plataforma se reserva no direito de manter os dados dos usuários e prestadores de serviço após 48 (quarenta e oito) meses de sua saída, para fins de pesquisa de mercado. 

3. COLETA E USO DE DADOS
A. Dados Que Coletamos
A CHAMA O SEU ZÉ coleta as seguintes categorias de dados, assim definidas:
Dados informados pelos usuários:
1.	Informações de conta, como nome, e-mail, número de telefone, endereço, dados de pagamento e geolocalização;
2.	Para Prestadores de Serviço, é possível coletar informações adicionais, como documentos de identificação e comprovação de capacidade profissional, de acordo com as exigências de segurança e conformidade a depender da espécie de serviço prestado.
Dados criados durante o uso dos nossos serviços:
1.	Dados de localização aproximada ou precisa, quando os serviços estão em uso. 
2.	Informações sobre transações realizadas, como valores pagos, produtos comprados, serviços solicitados e histórico de interações no aplicativo.
3.	Dados fornecidos por parceiros comerciais do "Chama o Seu Zé" ou fontes públicas, quando relevante para a prestação do serviço.

4. DIREITOS DOS TITULARES DE DADOS
A.	Direito de Acesso: O usuário tem o direito de obter confirmação sobre a existência de tratamento de seus dados pessoais e acesso a eles, podendo fazer apontamentos.
B.	Direito de Correção: O usuário pode solicitar a correção de dados incompletos, inexatos ou desatualizados.
C.	Direito de Portabilidade: O usuário tem direito de receber seus dados pessoais em formato estruturado e de uso comum, podendo transferi-los a outro controlador.
D.	Direito à Eliminação de Dados: O usuário pode solicitar a exclusão de seus dados pessoais tratados com base em consentimento, exceto quando o tratamento for necessário para cumprimento de obrigação legal ou regulatória, ressalvado o prazo adicional de 48 (quarenta e oito) meses que a plataforma utilizará para fins de pesquisa de mercado e compartilhamento com stakeholders.
E.	Direito de Oposição: O usuário pode se opor ao tratamento de dados realizado com base em uma das hipóteses de dispensa de consentimento, desde que não haja justificativa legítima para o tratamento.

5. COOKIES E TECNOLOGIAS DE RASTREAMENTO
A.	Uso de Cookies: O CHAMA O SEU ZÉ poderá utilizar cookies e tecnologias de rastreamento para melhorar a experiência do usuário na plataforma.
B.	Opção de Controle: O usuário poderá configurar seu navegador ou dispositivo para bloquear cookies ou alertar sobre seu uso, mas isso pode impactar a funcionalidade do aplicativo.

6. TERMOS E CONDIÇÕES ESPECÍFICOS PARA MENORES DE IDADE
A.	Tratamento de Dados de Menores: O tratamento de dados pessoais de menores de 18 anos será realizado apenas com o consentimento específico e destacado de um dos pais ou responsáveis legais.
B.	Finalidade: O tratamento de dados de menores será limitado ao estritamente necessário para a prestação de serviços da plataforma.

7. SEGURANÇA DOS DADOS
A.	Medidas Técnicas e Administrativas: O CHAMA O SEU ZÉ adotará medidas técnicas e administrativas para proteger os dados pessoais contra acessos não autorizados, perda, destruição ou qualquer forma de tratamento inadequado.
B.	Notificação de Incidentes de Segurança: Em caso de incidentes de segurança que possam gerar risco ou dano relevante aos usuários, o CHAMA O SEU ZÉ notificará a Autoridade Nacional de Proteção de Dados (ANPD) e os titulares dos dados afetados.

8. ATUALIZAÇÃO E REVISÃO DESTA POLÍTICA
A.	Periodicidade de Revisão: O CHAMA O SEU ZÉ revisará periodicamente este termo para garantir sua conformidade com novas normas e práticas de mercado.
B.	Comunicação de Alterações: Quaisquer alterações significativas nesta política serão comunicadas previamente aos usuários, garantindo-lhes a oportunidade de avaliar e, quando necessário, consentir com os novos termos.

9. COMO USAMOS OS DADOS
A CHAMA O SEU ZÉ utiliza dados para facilitar o acesso aos serviços oferecidos na plataforma de forma eficiente, segura e personalizada. Esses dados também são utilizados para:
A.	Melhorar a segurança dos usuários e prestadores de serviços;
B.	Fornecer suporte ao cliente;
C.	Realizar pesquisa e desenvolvimento;
D.	Viabilizar a comunicação entre prestadores de serviços e clientes;
E.	Fins de marketing e publicidade;
F.	Enviar comunicações não relacionadas a marketing, ainda que de forma automática;
G.	Atender a exigências legais;
H.	Pesquisa de mercado, mesmo que após a saída do usuário da Plataforma, pelo prazo de 48 (quarenta e oito) meses, incluindo o compartilhamento com stakeholders cadastrados na plataforma.

11. PRESTAÇÃO DOS SERVIÇOS
A CHAMA O SEU ZÉ utiliza os dados para operar, personalizar, manter e melhorar seus serviços, garantindo o bom funcionamento da plataforma. Entre os usos incluem-se:
A.	Criação e atualização de contas de assinantes, quando for o caso, e não assinantes.
B.	Facilitação de interações entre prestadores de serviços e clientes:
C.	Utilização de dados de localização para sugerir prestadores de serviços próximos aos clientes e facilitar a escolha de profissionais.
D.	Compartilhamento de informações essenciais, como nome e informações de contato, para facilitar a comunicação e a prestação de serviços.
E.	A alocação dos prestadores de serviços é feita com base em dados como proximidade geográfica, perfil do prestador, avaliações e histórico de atendimento.
F.	Cálculo de preços e estimativas com base nos dados fornecidos, como localização, tipo de serviço, e outras variáveis não pessoais, como horários e demanda.
G.	Processamento de pagamentos e transações realizadas entre clientes e prestadores por meio da plataforma, permitindo o pagamento eletrônico de maneira segura.
H.	Personalização da experiência do usuário, por exemplo, sugerindo prestadores de serviços preferidos ou localizados próximos de acordo com os dados de uso da plataforma.
I.	Geração de relatórios e recibos, além de atualizações sobre os serviços, termos e políticas da CHAMA O SEU ZÉ.
A plataforma realiza essas atividades para cumprir os termos do contrato com seus assinantes e para melhorar continuamente os serviços, conforme os interesses legítimos dos prestadores e usuários.

12. SEGURANÇA, PREVENÇÃO DE FRAUDES E PROTEÇÃO
A CHAMA O SEU ZÉ utiliza dados para garantir a segurança e integridade da plataforma e dos seus usuários, como:
A.	Verificação de identidade dos prestadores de serviços, garantindo que os profissionais cadastrados atendam aos requisitos exigidos, como histórico de atuação e avaliação de qualificações.
B.	Análise de comportamento na plataforma, utilizando dados como histórico de serviços prestados, localização e transações para detectar possíveis atividades fraudulentas ou uso indevido.
C.	Confirmação de identidade por meio de documentos e fotografias quando necessário, especialmente para assegurar a autenticidade dos prestadores e evitar a criação de múltiplas contas por um mesmo usuário.
Essas medidas são adotadas para garantir a segurança da plataforma e dos usuários, e podem resultar na desativação de contas em caso de irregularidades.

13. COMPARTILHAMENTO DE DADOS COM TERCEIROS
A.	Parcerias e Provedores de Serviços: O CHAMA O SEU ZÉ poderá compartilhar dados com terceiros para a prestação de serviços ou fornecimento de produtos, sempre garantindo que esses terceiros sigam as mesmas normas de proteção de dados e finalidades específicas aqui definidas, inclusive após a saída do usuário da plataforma, pelo prazo de 48 (quarenta e oito meses) para fins de pesquisa de mercado.
B.	Consentimento para Compartilhamento: Quando necessário, o consentimento específico para o compartilhamento de dados com terceiros será solicitado ao usuário, ficando desde já autorizado em relação a todos os parceiros que forem prestadores de serviço ou produtos cadastrados na plataforma;

14. TRANSFERÊNCIA INTERNACIONAL DE DADOS: 
A plataforma poderá transferir dados pessoais coletados para outros países, desde que eles estejam sujeitos a um nível de proteção adequado, seja para prestadores de serviços, clientes finais localizados no exterior ou para parceiros comerciais internacionais, e essas transferências ocorrerão apenas nos seguintes casos:
A.	Países com Nível Adequado de Proteção: A transferência será permitida para países ou organismos internacionais que proporcionem um grau de proteção de dados pessoais considerado adequado pela Autoridade Nacional de Proteção de Dados (ANPD), em conformidade com a legislação brasileira.
B.	Garantias Contratuais: Caso os dados sejam transferidos para países que não oferecem um nível adequado de proteção de dados, a transferência será realizada com base em garantias apropriadas, como cláusulas contratuais específicas ou acordos internacionais, conforme aprovado pela ANPD, que assegurem a proteção e os direitos dos titulares dos dados e tenham autorização específica do usuário.
C.	Consentimento Específico: Nos casos em que o nível de proteção no país de destino não seja considerado adequado e não houver garantias apropriadas, a transferência de dados será realizada mediante o consentimento explícito e informado do titular dos dados, após ele ser informado claramente sobre os riscos envolvidos na transferência internacional.
D.	Exceções Legais: Em determinadas situações, a transferência internacional de dados poderá ocorrer sem o consentimento do titular, como nos casos em que a transferência seja necessária para:
•	Execução de contrato entre o titular e o controlador, ou para a realização de procedimentos preliminares relacionados a contrato;
•	Cumprimento de obrigação legal ou regulatória pelo controlador;
•	Proteção da vida ou segurança física do titular ou de terceiros;
•	Execução de políticas públicas, desde que prevista em lei ou regulamento;
•	Exercício regular de direitos em processos judiciais, administrativos ou arbitrais;
•	Salvaguarda do crédito, conforme a legislação aplicável.
E.	Responsabilidades e Transparência: A CHAMA O SEU ZÉ se compromete a adotar medidas adequadas para assegurar que a transferência internacional de dados seja realizada de forma segura e transparente. Sempre que necessário, disponibilizaremos informações detalhadas sobre os países ou organizações internacionais destinatários dos dados e as garantias adotadas para proteger esses dados.
F.	Notificação de Transferências: A CHAMA O SEU ZÉ notificará previamente os usuários sobre qualquer transferência internacional de dados pessoais, exceto quando essa comunicação for dispensada por força de lei ou regulamento. A notificação incluirá o motivo da transferência, os destinatários dos dados e as garantias implementadas para a proteção dos dados pessoais.
 15. PESQUISA E DESENVOLVIMENTO
Os dados são utilizados pela CHAMA O SEU ZÉ para:
A.	Análise de dados e melhoria contínua dos serviços, incluindo o desenvolvimento de novos recursos e funcionalidades com base nas necessidades dos usuários e para pesquisa de mercado, mesmo após o desligamento do usuário, até o prazo de 48 (quarente e oito) meses.
B.	Testes e aprimoramento da experiência do usuário, com foco em tornar a plataforma mais intuitiva e eficaz.
Essas atividades são realizadas com base nos interesses legítimos da CHAMA O SEU ZÉ em oferecer um serviço de alta qualidade.
 
16. COMUNICAÇÃO ENTRE ASSINANTES E CLIENTES
A plataforma possibilita a comunicação direta entre prestadores e clientes, utilizando dados como:
A.	Nome e contato do prestador para facilitar o agendamento e execução dos serviços solicitados.  
B.	Comunicação via mensagens ou ligações para confirmar serviços ou esclarecer dúvidas.
Essas ações são fundamentais para garantir o bom andamento dos serviços e estão de acordo com os termos do contrato com os assinantes, sendo que o vazamento de dados pelas partes deverão ser notificados a plataforma, bem como a responsabilização pessoal recairá a quem der causa direta ao resultado danoso em concreto ou potencial.

 17. MARKETING E PUBLICIDADE
A CHAMA O SEU ZÉ pode utilizar dados coletados para:
A.	“Enviar comunicações de marketing”, como promoções, novos serviços e descontos por meio de e-mails, SMS, notificações no aplicativo ou outros canais de comunicação.
B.	“Exibir anúncios personalizados” de acordo com o histórico de uso do assinante, localização e interesses observados.

Esses anúncios podem ser relacionados tanto aos serviços oferecidos pela plataforma quanto por parceiros comerciais, e visam promover serviços relevantes para os usuários.

 18. COMUNICAÇÕES SEM FINS DE MARKETING
Além de campanhas promocionais, a “Chama o Seu Zé” pode usar dados para:
A.	Enviar pesquisas e comunicações sobre melhorias na plataforma ou sobre a experiência do usuário, com foco no aprimoramento dos serviços.
Essas comunicações são realizadas de acordo com os interesses legítimos da CHAMA O SEU ZÉ em manter os usuários informados sobre o funcionamento da plataforma e oportunidades de aprimoramento.

 19. PROCEDIMENTOS E EXIGÊNCIAS LEGAIS
A “Chama o Seu Zé” pode utilizar dados para:
A.	Cumprir obrigações legais e regulatórias, como responder a processos judiciais, reclamações de usuários, ou atender solicitações de autoridades.
Essas atividades são realizadas para garantir que a CHAMA O SEU ZÉ opere de forma segura e em conformidade com a legislação aplicável.

A CHAMA O SEU ZÉ retém os dados do usuário pelo tempo necessário para as finalidades descritas neste termo, que variam de acordo com o tipo de dados, a categoria de usuário a quem os dados se referem, as finalidades para as quais coletamos os dados e se eles devem ser retidos após uma solicitação de exclusão de conta para os fins descritos abaixo. Por exemplo, retemos dados para:
A.	por toda a vida útil das contas dos usuários, se esses dados forem necessários para prestar nossos serviços. P.ex., dados da conta.
B.	por 7 anos, se necessário para cumprir as exigências fiscais decorrentes da prestação de serviços.
C.	Por 48 (quarenta e oito meses) após o desligamento da plataforma, com a finalidade de pesquisa de mercado, ai incluindo stakeholders cadastrados na plataforma.

Os usuários podem solicitar a exclusão da conta a qualquer momento pelo app, no menu "Configurações > Privacidade", ou pelo site da “Chama seu Zé”. (colocar passo a passo de como excluir). 

Após solicitações de exclusão da conta, serão excluídos inclusive os dados, exceto em caso de necessidade para fins de segurança, prevenção de fraudes ou conformidade com requisitos legais, ou devido a problemas relacionados à conta do usuário (como um crédito pendente, reclamação ou disputa não resolvida). 

20. OPÇÕES E TRANSPARÊNCIA
A CHAMA SEU ZÉ permite que os usuários acessem e/ou controlem os dados que coleta, inclusive por meio de: 
A.	Configurações de privacidade
B.	Permissões do dispositivo
C.	Páginas de avaliação no app
D.	Opções de marketing e publicidade

21. FORO E LEGISLAÇÃO APLICÁVEL
A.	Legislação Aplicável: Este termo será regido pelas leis da República Federativa do Brasil, em especial pela LGPD.
B.	Foro Competente: Fica eleito o foro da Comarca de São Paulo, Estado de São Paulo, para dirimir quaisquer questões decorrentes deste termo, com renúncia a qualquer outro, por mais privilegiado que seja

São José do Rio Preto, 09 de outubro de 2024

        </p>
    )
}

export default TermoPrestadorNaoAssinante;