import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';

const ResetPasswordProvider = () =>{
    const {token} = useParams()
    const navigate = useNavigate()
    const validationSchema = yup.object().shape({
        newPassword: yup.string().required('Campo Obrigatório').min(8),
        confirmNewPassword: yup.string().oneOf([yup.ref("newPassword"), null], "As Senhas não são iguais"),

    })

    const handleSubmit = async (values)=>{
        try {
            const response = await axios.post(`https://app.chamaoseuze.com.br/resetPasswordProvider/${token}`, values)
            if(response.data.message = 'Senha redefinida com sucesso'){
                navigate('/LoginP')
            }
        } catch (error) {
            console.error(error)
        }
    }
    
    return(
        <div className="mainResetPassword">
            <Formik
            initialValues={{}}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            >
                <Form style={{display:'flex', alignItems:"center", justifyContent:"center",gap:"10px"}}>

                    <Field style={{width:'20%'}} name='newPassword' type='password' placeholder="Digite sua nova senha"/>
                    <ErrorMessage name="newPassword" component={"span"}/>
                    
                    <Field style={{width:'20%'}} name='confirmNewPassword' type='password' placeholder="Digite sua nova senha"/>
                    <ErrorMessage name="confirmNewPassword" component={"span"}/>

                    <button>Salvar</button>
                    
                </Form>
            </Formik>
        </div>
    )
}


export default ResetPasswordProvider;