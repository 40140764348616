import React from 'react';
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Welcome from './pages/welcome/bemvindo';
import Login from './pages/login/login';
import Cadastro from './pages/cadastro/cadastro';
import ClientProfile from './pages/perfilcliente/perfilcliente';
import MapaPage from './pages/mapapage/mapapage';
import ServicoEmAndamento from './pages/serviçoandamentocliente/serviçoandamentocliente';

import CadastroP from './pagesPrestador/cadastro/cadastro';
import LoginPrestadores from './pagesPrestador/LoginP/LoginP';
import Principal from './pagesPrestador/principal/principal';
import Profile from './pagesPrestador/perfil/perfil';
import ServicosProximos from './pagesPrestador/serviçosproximos/serviçosproximos';
import ServiceInProgress from './pagesPrestador/serviçoemandamento/ServiceInProgress';
import Faturamento from './pagesPrestador/faturamento/faturamento';
import AdminDashboard from './admpages/principaladm/principaladm';
import ProvidersManagement from './admpages/adicionarosprestadores/ProvidersManagement';
import ConsumersManagement from './admpages/adicionarconsumidores/ConsumersManagement';
import ServicesManagement from './admpages/adicionarserviços/ServicesManagement';
import ServiceRequest from './pages/ServiceSearch/ServiceSearch';
import AguardandoAceitacao from './pages/AwaitingAcceptance/AwaitingAcceptance';
import PaymentGateway from './pages/Paymentsgetway/Paymentsgetway';
import AguardandoPagamento from './pagesPrestador/aguardandopagamento/aguardandopagamento';
import AvaliacaoCliente from './pagesPrestador/avaliarcliente/avaliarcliente';
import AvaliacaoPrestador from './pages/avaliarprestador/avaliarprestador';
import Serviconaoterminado from './pagesPrestador/serviçosnãoterminados/serviçonaoterminado';
import ServiconaoterminadoC from './pages/servicosnaoterminadosclientes/servicosnaoterminadosclientes';
import CalendarioAgendamentos from './pages/telaagendamentocliente/teladeagendamentocliente';
import CalendarioAgendamentosP from './pagesPrestador/teladeagendamentoprestador/teladeagendamentoprestador';
import PrivateRoute from './components/PrivateRoutes';
import PrivateRouteP from './components/PrivateRoutesP';
import PaymentCompleted from './pages/Paymentsgetway/PaymentCompleted';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ResetPasswordProvider from './pages/ResetPassword/ResetPasswordProvider';
import AuthMercadoPago from './pagesPrestador/MercadoPagoConnect/MercadoPagoConnect';
import { CreateAccountAdmin } from './admpages/Login/CreateAccountAdmin';
import { LoginAdmin } from './admpages/Login/Login';
import { PrivateRoutesAdmin } from './admpages/PrivateRoutes';
import PaymentGatewayCancel from './pages/Paymentsgetway/paymentCancel';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Welcome />} />
        /*Telas prestador*/
        <Route path="/CadastroP" element={<CadastroP />} />
        <Route path="/LoginP" element={<LoginPrestadores />} />
        <Route path='/Principal/:id' element={<PrivateRouteP element={<Principal/>}/>}   />
        <Route path='/Profile/:id' element={<PrivateRouteP element={<Profile/>}/>}  />
        <Route path="/faturamento/:id" element={<PrivateRouteP element={<Faturamento/>}/>}   />
        <Route path='/ServiçosProximos/:id' element={<PrivateRouteP element={<ServicosProximos/>}/>}   />
        <Route path='/servico-em-andamento/:id' element={<PrivateRouteP element={<ServiceInProgress/>}/>}  />
        <Route path="/aguardando-pagamento/:id" element={<PrivateRouteP element={<AguardandoPagamento/>}/>}  />
        <Route path="/avaliarcliente/:id" element={<PrivateRouteP element={<AvaliacaoCliente/>}/>}  />
        <Route path='/servicosemandamentos/:id' element={<PrivateRouteP element={<Serviconaoterminado/>}/>}  />
        <Route path='/AgendaPrestador/:id' element={<PrivateRouteP element={<CalendarioAgendamentosP/>}/>}  />
        <Route path='/esqueci-minha-senha-prestador/:token' element={<PrivateRouteP element={<ResetPasswordProvider/>}/>} />
        <Route path= '/authMercadoPago' element={<PrivateRouteP element={<AuthMercadoPago/>}/>} />
        
        /*Telas Consumidor*/
        <Route path="/login" element={<Login />} />
        <Route path='/esqueci-minha-senha/:token' element={<ResetPassword/>}/>
        <Route path="/Cadastro" element={<Cadastro />} />
        <Route path="/MapaPage/:id" element={ <PrivateRoute element={<MapaPage />}/>} />
        <Route path="/Serviços/:id" element={<PrivateRoute  element={<ServiceRequest />}/>}/>
        <Route path='/aguardando-aceitacao/:id'element={<PrivateRoute  element={<AguardandoAceitacao />}/>} />
        <Route path="/serviçoemandamento/:id"  element={<PrivateRoute  element={<ServicoEmAndamento />}/>}   />
        <Route path="/ClienteProfile/:id"  element={<PrivateRoute  element={<ClientProfile />}/>}  />
        <Route path='/Agenda/:id'  element={<PrivateRoute  element={<CalendarioAgendamentos />}/>} />
        <Route path='/payment-gateway/:id'  element={<PrivateRoute  element={<PaymentGateway />}/>}/>
        <Route path='/payment-gateway-cancel/:id'  element={<PrivateRoute  element={<PaymentGatewayCancel />}/>}/>
        <Route path='confirmar-pagamento/:id'  element={<PrivateRoute  element={<PaymentCompleted />}/>}/>
        <Route path='/avaliarPrestador/:id'  element={<PrivateRoute  element={<AvaliacaoPrestador />}/>}/>   
        <Route path='/servicosemandamentosclientes/:id'  element={<PrivateRoute  element={<ServiconaoterminadoC />}/>} />   
        /*Telas do Administrador */
        {/* <Route path='/CriarConta' element={<CreateAccountAdmin/>}/> */}
        <Route path='/LoginAdmin' element={<LoginAdmin/>}/>
        <Route path='/Principal-Administrador/:token/:id' element={<PrivateRoutesAdmin element={<AdminDashboard/>}/>}/>
        <Route path='/ProvidersManagement/:token/:id' element={<PrivateRoutesAdmin element={<ProvidersManagement/>}/> }/>
        <Route path='/ConsumersManagement/:token/:id' element={<PrivateRoutesAdmin element={<ConsumersManagement/>}/>}/>
        <Route path='/ServicesManagement/:token/:id'element={<PrivateRoutesAdmin element={<ServicesManagement/>}/>}/>

      </Routes>
    </Router>
  );
}

export default App;
