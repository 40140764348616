import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AuthMercadoPago() {
  const navigate = useNavigate();
  const id = localStorage.getItem("userId");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const access_token = urlParams.get('token');
    const user_id = urlParams.get('user_id');

    if (access_token && user_id) {
      // Salva os tokens recebidos no banco
      salvarTokensNoBanco(access_token, user_id);
      navigate(`/Principal/${id}`);
    } else {
      redirectToMercadoPagoAuth();
    }
  }, [navigate, id]);

  async function salvarTokensNoBanco(access_token, user_id) {
    try {
      const response = await axios.post('https://app.chamaoseuze.com.br/api/auth/mercadopago', {
        access_token,
        user_id,
        id,
      });
    } catch (error) {
      console.error('Erro ao salvar tokens no banco:', error);
    }
  }

  function redirectToMercadoPagoAuth() {
    window.location.href = 'https://app.chamaoseuze.com.br/api/authorize';
  }

  return (
    <div>
      <h1>Autorizando...</h1>
    </div>
  );
}

export default AuthMercadoPago;
