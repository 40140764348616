import React from 'react';
import styled from 'styled-components';

const StarRating = ({ rating, setRating }) => {
  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  return (
    <StarContainer>
      {[...Array(5)].map((star, index) => (
        <Star
          key={index}
          filled={index < rating}
          onClick={() => handleStarClick(index)}
        >
          ★
        </Star>
      ))}
    </StarContainer>
  );
};

export default StarRating;

const StarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Star = styled.span`
  font-size: 2rem;
  color: ${props => (props.filled ? '#ffcc00' : '#ccc')};
  cursor: pointer;
  
  &:hover {
    color: #ffcc00;
  }
`;
