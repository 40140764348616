import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './getway.css';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
initMercadoPago('APP_USR-b8cd3da9-4cde-45c1-a23f-151af9f6515b');

const PaymentGatewayCancel = () => {
    const [paymentMethod, setPaymentMethod] = useState('credit-debit-card');
    const [serviceValue, setServiceValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userLoading, setUserLoading] = useState(true); // Estado de carregamento do usuário
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [nameService, setNameService] = useState(null);
    const [idprestador, Setidprestador] = useState(null);
    const [userIdMercadoPago, setUserIdMercadoPago] = useState(null);
    const [nomeprestador, Setnomeprestador] = useState(null);
    const [idcliente, setIdCliente] = useState(null);
    const [user, setUser] = useState(null);
    const[idItem,setIdItem] = useState(null)

    useEffect(() => {
        const fetchServiceValue = async () => {
            try {
                const response = await axios.get(`https://app.chamaoseuze.com.br/pedidos/${id}`);
                const preco = parseFloat(response.data.preco);
                const idprestador = response.data.idprestador;
                const prestador = response.data.provider;
                const idcliente = response.data.idcliente;
                const service = response.data.nome_servico;

                setNameService(service);
                setIdCliente(idcliente);
                setServiceValue(preco);
                Setidprestador(idprestador);
                Setnomeprestador(prestador);

                // Fetch Cliente após definir o idcliente
                await fetchCliente(idcliente);
                await fetcheUserIdMercadoPago(idprestador)
            } catch (err) {
                setError('Erro ao carregar o valor do serviço.');
            } finally {
                setLoading(false);
            }
        };

        const fetchCliente = async (id) => {
            setUserLoading(true); // Ativa o carregamento do usuário
            try {
                const response = await axios.get(`https://app.chamaoseuze.com.br/cliente/${id}`);
                const user = response.data;
                setUser(user);
            } catch (error) {
                setError('Erro ao carregar dados do cliente.');
            } finally {
                setUserLoading(false); // Desativa o carregamento do usuário
            }
        };

        const fetcheUserIdMercadoPago = async (id)=>{
            try {
                const response = await axios.get(`https://app.chamaoseuze.com.br/prestador/${id}`)
                setUserIdMercadoPago(response.data.accessToken)

            } catch (error) {
                console.error(error.data || error.message);
            }
        }
        fetchServiceValue();
    }, [id, userIdMercadoPago]);

    useEffect(()=>{
        
        const getIdKey = async () => {
            const values = {
                title: nameService,
                unit_price: serviceValue,
                userIdMercadoPago: userIdMercadoPago,
                id,
            };
            try {
                const response = await axios.post('https://app.chamaoseuze.com.br/getIdKeyCancel', values);
                setIdItem(response.data.id);
            } catch (error) {
                console.error(error);
            }
        };
        

          getIdKey()
    }, [userIdMercadoPago, nameService, serviceValue, id])

    return (
        <div className="payment-container">
            {idItem &&(
                <Wallet initialization={{ marketplace:true, preferenceId: idItem }}/>
            )}

        </div>
    );
};

export default PaymentGatewayCancel;