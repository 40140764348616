import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';



const PrivateRouteP = ({ element }) => {
  const  id  = localStorage.getItem('userId');  
  const token = localStorage.getItem('authToken');

  if (!token) {
    return <Navigate to="/LoginP" />;
  }

  try {
    // Decodifica o token para obter o id do usuário
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.id;

    // Verifica se o id do usuário corresponde ao id na URL
    if (id && userId !== parseInt(id)) {
      return <Navigate to="/LoginP" />;
    }

    return element;
  } catch (error) {
    console.error('Erro ao decodificar o token:', error);
    return <Navigate to="/LoginP" />;
  }
};

export default PrivateRouteP;
