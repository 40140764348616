// src/components/AdminDashboard.js

import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './AdminDashboard.css'; // Arquivo CSS para estilos
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const AdminDashboard = () => {

  const { id, token } = useParams();
  // Estado para armazenar os dados
  const [providers, setProviders] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartDataConsumers, setChartDataConsumers] = useState([]);
  const [consumers, setConsumers] = useState([]);

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const response = await axios.get(`https://app.chamaoseuze.com.br/prestadores`);
        setProviders(response.data);


        const groupedByMonth = response.data.reduce((acc, provider) => {
          const createdAt = new Date(provider.createdAt);
          const month = createdAt.toLocaleString('default', { month: 'short' }); // "Jan", "Feb", ...
          const year = createdAt.getFullYear();
          const key = `${month}/${year}`; // Ex.: "Jan/2024"

          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += 1; // Incrementa o número de prestadores no mês
          return acc;
        }, {});

        // Formatar dados para o gráfico
        const formattedData = Object.entries(groupedByMonth).map(([key, value]) => ({
          name: key,
          prestadores: value,
        }));

        setChartData(formattedData);
      } catch (error) {
        console.error(error);
      }
    }
    const fetchConsumers = async () => {
      try {
        const response = await axios.get("https://app.chamaoseuze.com.br/getAllUsers");

        setConsumers(response.data);


        const groupedByMonth = response.data.reduce((acc, consumers) => {
          const createdAt = new Date(consumers.createdAt);
          const month = createdAt.toLocaleString('default', { month: 'short' });
          const year = createdAt.getFullYear();
          const key = `${month}/${year}`;

          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += 1;
          return acc;
        }, {});



        // Formatar dados para o gráfico
        const formattedData = Object.entries(groupedByMonth).map(([key, value]) => ({
          name: key,
          clientes: value,
        }));

        setChartDataConsumers(formattedData);
      } catch (error) {
        console.error(error.message || error.data)
      }
    }
    fetchConsumers();
    fetchProviders();
  }, []);

  const navigate = useNavigate()

  function prestador() {
    navigate(`/ProvidersManagement/${token}/${id}`)
  }
  function consumidor() {
    navigate(`/ConsumersManagement/${token}/${id}`)
  }
  function Serviços() {
    navigate(`/ServicesManagement/${token}/${id}`)
  }

  return (
    <div className="dashboard-container">

      <header>
        <h1 className='dash'>Dashboard Administrador</h1>
      </header>

      <main>
        <section className="dashboard">
          <h2>Dashboard</h2>
          <div className="dashboard-content">
            {chartData.length > 0 ? (
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="prestadores" stroke="#003366" />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <p>Carregando gráfico...</p>
            )}
            {chartDataConsumers.length > 0 ? (
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={chartDataConsumers}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="clientes" stroke="#003366" />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <p>Carregando gráfico...</p>
            )}
          </div>

        </section>



        <section className="online-list">
          <div className="section-header">
            <h2>Prestadores</h2>
            <button className="navigate-btn" onClick={prestador}>Ver Todos</button>
          </div>
          <ul>
            {providers.map((provider, index) => (
              <li key={index}>
                {provider.nomeCompleto}
              </li>
            ))}
          </ul>
        </section>
        <section className="consumer-list">
          <div className="section-header">
            <h2>Consumidores</h2>
            <button className="navigate-btn" onClick={consumidor}>Ver Todos</button>
          </div>
          <ul>
            {consumers.map((consumer, index) => (
              <li key={index}>
                {consumer.nome}
              </li>
            ))}
          </ul>
        </section>
        <footer>
          <button className="btn" onClick={Serviços}>Serviços</button>
        </footer>
      </main>
    </div>
  );
};

export default AdminDashboard;
