// BackButton.js
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './BackButton.css'; // Opcional: para estilizar o botão

const BackButton = () => {
  const id = localStorage.getItem('userId');
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/MapaPage/${id}`); // Redireciona para a página principal
  };

  return (
    <button className="back-button" onClick={handleClick}>
      Voltar
    </button>
  );
};

export default BackButton;
