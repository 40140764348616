import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import StarRating from './StarRating'; // Um componente de avaliação por estrelas que vamos criar

const AvaliacaoCliente = () => {
  const { id } = useParams(); // Esse id é do pedido
  const [cliente, setCliente] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPedido = async () => {
      try {
        const responsePedido = await axios.get(`https://app.chamaoseuze.com.br/pedidos/${id}`);
        const idCliente = responsePedido.data.idcliente;

        const responseCliente = await axios.get(`https://app.chamaoseuze.com.br/cliente/${idCliente}`);
        setCliente(responseCliente.data);
      } catch (err) {
        console.error('Erro ao carregar os dados do pedido ou do cliente:', err); // Log de erro
        setError('Erro ao carregar os dados do pedido ou do cliente.');
      } finally {
        setLoading(false);
      }
    };

    fetchPedido();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responsePedido = await axios.get(`https://app.chamaoseuze.com.br/pedidos/${id}`);
      const idCliente = responsePedido.data.idcliente;
      const idprestador = responsePedido.data.idprestador;

      const response = await axios.post('https://app.chamaoseuze.com.br/avaliacao-cliente', {
        idusuario: idCliente,
        idpedido: id,
        rating,
        feedback,
      });
      alert('Avaliação enviada com sucesso!');
      navigate(`/Principal/${idprestador}`); // Redireciona para a página principal após a avaliação
    } catch (err) {
      if (err.response) {
        // Erro com resposta da API
        console.error('Erro ao enviar a avaliação:', err.response.data); // Log detalhado do erro
        setError(`Erro ao enviar a avaliação: ${err.response.data.message || 'Erro desconhecido'}`);
      } else if (err.request) {
        // Erro de solicitação
        console.error('Erro ao enviar a avaliação:', err.request); // Log detalhado do erro
        setError('Erro ao enviar a avaliação: Nenhuma resposta recebida do servidor.');
      } else {
        // Outro erro
        console.error('Erro ao enviar a avaliação:', err.message); // Log detalhado do erro
        setError(`Erro ao enviar a avaliação: ${err.message}`);
      }
    }
  };

  if (loading) {
    return <Loading>Carregando...</Loading>;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <Container>
      <Header>Avaliar Cliente</Header>
      <Content>
        <ClienteInfo>
          <h2>{cliente?.nome}</h2>
          <p><strong>Email:</strong> {cliente?.email}</p>
          <p><strong>Telefone:</strong> {cliente?.telefone}</p>
        </ClienteInfo>
        <Form onSubmit={handleSubmit}>
          <StarRating rating={rating} setRating={setRating} />
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Deixe seu feedback"
            rows="5"
          ></textarea>
          <button type="submit">Enviar Avaliação</button>
        </Form>
      </Content>
    </Container>
  );
};

export default AvaliacaoCliente;

// Styled Components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #001f3f;
  color: #ffffff;
  overflow:hidden;
`;

const Header = styled.header`
  width: 100%;
  padding: 20px;
  background-color: #ff6600;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
`;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ClienteInfo = styled.div`
  background-color: #003366;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  
  h2 {
    color:white;
    margin-top: 0;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 600px;
  
  textarea {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    resize: none;
  }
  
  button {
    background-color: #ff6600;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    
    &:hover {
      background-color: #cc5200;
    }
  }
`;

const Loading = styled.div`
  font-size: 1.5rem;
  color: #ff6600;
`;

const Error = styled.div`
  font-size: 1.5rem;
  color: #ff6600;
`;
